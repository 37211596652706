import Config from "../../config";

const initState = {
  list: [],
  meta: Config.defaultMeta,
  error: false,
  errorMessage: "",
  created: false,
  updated: false,
  deleted: false,
  loading: false,
  loadingText: "Loading...",
  isnewuser: false,
  selected: {},
  data: {
    recipients: {
      firstname: null,
      middlename: null,
      lastname: null,
      mobileNumber: null,
      accountNumber: null,
      type: null,
      statusCode: null,
      statusDescription: null,
    },
  },
  open: false,
};

export default function recipients(state = initState, action) {
  switch (action.type) {
    case "LIST_RECIPIENTS":
      let { meta } = action;
      return {
        ...state,
        list: action.payload,
        meta: meta,
        loading: false,
      };

    case "RECIPIENTS_AUTOCOMPLETE":
      // let autosuggestbox = [];
      //
      // if (action.payload != null) {
      //   action.payload.forEach((item) => {
      //     let detail = {
      //       id            : item.id,
      //       typeId        : item.channel.id,
      //       type          : item.channel.type,
      //       firstname     : item.firstname || "",
      //       middlename    : item.middlename || "",
      //       lastname      : item.lastname || "",
      //       accountNumber : item.accountNumber || "",
      //       companyName   : item.companyName || "",
      //       accountName   : item.companyName || (item.firstname + ' ' + item.lastname)
      //     }
      //     autosuggestbox.push(detail);
      //   })
      // }

      return {
        ...state,
        list: action.payload,
        meta: meta ? meta : Config.defaultMeta,
        loading: false,
      };

    case "SHOW_RECIPIENT":
      return {
        ...state,
        open: true,
      };

    case "HIDE_RECIPIENT":
      return {
        ...state,
        open: false,
      };

    case "FETCH_RECIPIENTS_LOAD":
      return {
        ...state,
        selected: {
          ...state.data,
          loaded: false,
        },
      };

    case "FETCH_RECIPIENT_DETAILS":
      return {
        ...state,
        isnewuser: false,
        loading: false,
        selected: {
          id: action.payload.id,
          firstname: action.payload.firstname,
          middlename: action.payload.middlename,
          lastname: action.payload.lastname,
          companyname: action.payload.companyName,
          mobileNumber: action.payload.mobileNumber,
          address1: action.payload.address1,
          address2: action.payload.address2,
          city: action.payload.city,
          zipCode: action.payload.zipCode,
          state: action.payload.state,
          country: action.payload.country,
          bankName: action.payload.bankName,
          accountNumber: action.payload.accountNumber,
          bankIban: action.payload.bankIban,
          bankBic: action.payload.bankBic,
          bankBvn: action.payload.bankBvn,
          type: action.payload.type,
          productId: action.payload.productId,
          statusCode: action.payload.statusCode,
          statusDescription: action.payload.statusDescription,
          channel: action.payload.channel.id,
        },
      };

    case "FETCH_RECIPIENTS":
      return {
        ...state,
        selected: {
          ...state.data,
          loaded: true,
        },
        loading: false,
      };

    case "RECIPIENTS_LOADING":
      return {
        ...state,
        loading: true,
        loadingText: "Updating recipient...",
      };

    case "LOADED":
      return {
        ...state,
        loading: false,
        loaded: true,
      };

    case "RECIPIENTS_CREATED":
      return {
        ...state,
        isnewuser: true,
        created: true,
        loading: false,
        selected: {},
      };

    case "RECIPIENTS_DELETED":
      return {
        ...state,
        deleted: true,
        loading: false,
      };

    case "RECIPIENTS_UPDATED":
      return {
        ...state,
        isnewuser: false,
        updated: true,
        loading: false,
      };

    case "RECIPIENTS_ERROR":
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case "LOGOUT":
      return initState;

    default:
      return state;
  }
}
