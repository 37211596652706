import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert } from "reactstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Aux from "../../../hoc/_Aux";
import LoaderButton from "../../../App/components/LoaderButton";

import { connect } from "react-redux";
import {
  newUser,
  createUser,
  dismissError,
  setLoading,
} from "../../../store/actions/users";

import Config from "../../../config";
import FooterSpan from "../../../App/components/FooterSpan";

import startsWith from "lodash.startswith";

import classNames from "classnames";

class RegisterUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: "",
      middlename: "",
      lastname: "",
      email: "",
      phoneNumber: process.env.REACT_APP_DEFAULT_COUNTRY_PHONE_CODE,
      dialCode: process.env.REACT_APP_DEFAULT_COUNTRY_PHONE_CODE,
      boolPhoneLenError: false,
      boolPhoneInvalid: true,
    };
    this.form = React.createRef();
    this.validateForm = this.validateForm.bind(this);
  }

  componentDidMount() {
    this.props.newUser();
  }

  validateForm = () => {
    return (
      !this.state.boolPhoneLenError &&
      !this.state.boolPhoneInvalid &&
      this.state.firstname.length > 0 &&
      this.state.lastname.length > 0 &&
      this.state.email.length > 0
    );
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handlePhoneChange = (phone, countryData) => {
    this.setState({
      phoneNumber: phone,
      dialCode: countryData.dialCode,
      boolPhoneLenError: false,
    });
  };

  handleBackToLogin = (event) => {
    this.props.dismissError();
    this.props.history.push("/login");
  };

  handleCreateUser = (event, errors, values) => {
    event.preventDefault();

    if (this.state.dialCode.length >= this.state.phoneNumber.length) {
      this.setState({ boolPhoneLenError: true });
      return;
    }

    let tempUserPayload = {
      firstname: this.state.firstname,
      middlename: this.state.middlename,
      lastname: this.state.lastname,
      email: this.state.email,
      phoneNumber: "+" + this.state.phoneNumber,
      tenant: Config.REACT_APP_TENANT,
      gender: "male",
    };

    var userPayload = {};
    //clean the values first
    for (const [key, value] of Object.entries(tempUserPayload)) {
      userPayload[key] = value.trim();
    }

    this.props.setLoading();
    this.props.createUser(userPayload);

    this.setState({
      firstname: "",
      middlename: "",
      lastname: "",
      email: "",
      phoneNumber: process.env.REACT_APP_DEFAULT_COUNTRY_PHONE_CODE,
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.auth.token !== undefined) {
      sessionStorage.setItem("usersession", JSON.stringify(this.props.auth));
      this.props.userHasAuthenticated(
        true,
        this.props.auth.user.account.status !== 8
      );
      this.props.history.push(Config.REACT_APP_DEFAULT_LANDING_URI);
    }

    if (!prevProps.user.userCreated && this.props.user.userCreated) {
      this.props.history.push("/login");
    }
  }

  onBlurPhoneInput = (e, country) => {
    let inputNumber = e.target.value.substring(1);
    inputNumber = inputNumber.replaceAll(" ", "");
    let { dialCode, format } = country;

    if (dialCode.length === inputNumber.length) {
      this.setState({ boolPhoneLenError: true });
    }

    let intFormatLength = 0;
    for (var i = 0; i < format.length; i++) {
      if (format[i] === ".") {
        intFormatLength++;
      }
    }

    if (
      inputNumber.length > dialCode.length &&
      inputNumber.length < intFormatLength / 2
    ) {
      this.setState({ boolPhoneError: true });
    } else {
      this.setState({ boolPhoneError: false });
    }
  };

  render() {
    let phoneInputProps = {
      name: "phoneInput",
      id: "phoneInput",
      required: true,
      autoFocus: true,
    };

    let phoneInputClass = classNames("ml-5", {
      "invalid-number": this.state.boolPhoneLenError,
    });

    return (
      <Aux>
        <div className="d-flex auth-wrapper aut-bg-img flex-column justify-content-between">
          <div style={{ margin: "30px" }}>
            <div>
              <img
                src={
                  "/assets/tenants/" +
                  process.env.REACT_APP_TENANT.toLowerCase() +
                  "/logo.png"
                }
                alt=""
                className="headerLogo"
              />
            </div>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div className="auth-content-register">
              <div className="card">
                <div className="card-header d-flex flex-column justify-content-center align-items-center">
                  <h3 className="mb-4">
                    {process.env.REACT_APP_REGISTER_ACTION_TEXT}
                  </h3>
                  <span className="text-italic">
                    {process.env.REACT_APP_CATCHPHRASE_2}
                  </span>
                </div>
                <div className="card-body">
                  <Alert
                    color="danger"
                    isOpen={this.props.auth.error}
                    toggle={this.props.dismissError}
                  >
                    {this.props.auth.errorMsg}
                  </Alert>
                  <div>
                    <form
                      ref={(f) => (this.form = f)}
                      onSubmit={this.handleCreateUser}
                    >
                      <PhoneInput
                        country={process.env.REACT_APP_DEFAULT_COUNTRY_ISO2_CODE.toLowerCase()}
                        onChange={this.handlePhoneChange}
                        // disabled={this.props.isViewMode}
                        placeholder="Mobile Number"
                        containerClass="input-group"
                        inputClass={phoneInputClass}
                        // onBlur={this.onBlurPhoneInput}
                        inputProps={phoneInputProps}
                        prefix="+"
                        value={this.state.phoneNumber}
                        countryCodeEditable={false}
                        defaultErrorMessage="Invalid Phone Number"
                        isValid={(inputNumber, country, countries) => {
                          let { format, dialCode } = country;

                          let BoolIsValid = false;

                          BoolIsValid =
                            startsWith(inputNumber, dialCode) ||
                            startsWith(dialCode, inputNumber);

                          let intFormatLength = 0;
                          for (var i = 0; i < format.length; i++) {
                            if (format[i] === ".") {
                              intFormatLength++;
                            }
                          }

                          if (
                            inputNumber.length > dialCode.length &&
                            inputNumber.length < intFormatLength / 2
                          ) {
                            BoolIsValid = false;
                          } else {
                            BoolIsValid = true;
                          }

                          if (BoolIsValid === this.state.boolPhoneInvalid) {
                            this.setState({
                              boolPhoneInvalid: !BoolIsValid,
                            });
                          }

                          return BoolIsValid;
                        }}
                        required
                      />

                      {this.state.boolPhoneLenError && (
                        <label className="text-danger">
                          Required Mobile Number
                        </label>
                      )}

                      <div className="input-group mt-3 mb-3">
                        <input
                          type="email"
                          id="email"
                          className="form-control"
                          placeholder="Email Address"
                          required
                          value={this.state.email}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          id="firstname"
                          className="form-control"
                          placeholder="First Name"
                          required
                          value={this.state.firstname}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          id="middlename"
                          className="form-control"
                          placeholder="Middle Name"
                          value={this.state.middlename}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="input-group mb-4">
                        <input
                          type="text"
                          id="lastname"
                          className="form-control"
                          placeholder="Surname"
                          required
                          value={this.state.lastname}
                          onChange={this.handleChange}
                        />
                      </div>
                      <LoaderButton
                        block
                        disabled={
                          this.props.auth.loading ||
                          this.props.user.loading ||
                          !this.validateForm()
                        }
                        type="submit"
                        isLoading={
                          this.props.auth.loading || this.props.user.loading
                        }
                        text="Register"
                        loadingText="Creating account..."
                        className="px-4 btn-login"
                      />
                    </form>
                  </div>
                </div>
                <div className="card-footer d-flex flex-row justify-content-center align-items-center">
                  <div className="d-flex flex-column">
                    <h5>{process.env.REACT_APP_ASK_REGISTER_CATCHPHRASE}</h5>
                    <span className="">
                      {process.env.REACT_APP_ASK_REGISTER_SUB_CATCHPHRASE}
                    </span>
                  </div>
                  <div style={{ minWidth: "100px" }}>
                    <LoaderButton
                      block
                      disabled={
                        this.props.auth.loading || this.props.user.loading
                      }
                      onClick={this.handleBackToLogin}
                      text="Login"
                      className="px-4 btn-secondary ml-3"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="login-footer">
              <span className="text-white">
                {process.env.REACT_APP_MAIN_CATCHPHRASE}
              </span>
            </div>
          </div>
          <div style={{ margin: "30px 0px" }}>
            <div className="footer-contact-info">
              <ul>
                {process.env.REACT_APP_SIGNIN_PAGE_ADDRESS && (
                  <li>
                    <FontAwesomeIcon icon={["fas", "map-marker-alt"]} />{" "}
                    {process.env.REACT_APP_SIGNIN_PAGE_ADDRESS}
                  </li>
                )}
                {process.env.REACT_APP_SIGNIN_PAGE_EMAIL && (
                  <li>
                    <FontAwesomeIcon icon={["fas", "envelope"]} /> Email:{" "}
                    <a
                      href={`mailto:${process.env.REACT_APP_SIGNIN_PAGE_EMAIL}`}
                    >
                      {process.env.REACT_APP_SIGNIN_PAGE_EMAIL}
                    </a>
                  </li>
                )}
                {process.env.REACT_APP_SIGNIN_PAGE_COMPLIANCE_EMAIL && (
                  <li>
                    <FontAwesomeIcon icon={["fas", "envelope"]} /> Compliance
                    Email:{" "}
                    <a
                      href={`mailto:${process.env.REACT_APP_SIGNIN_PAGE_COMPLIANCE_EMAIL}`}
                    >
                      {process.env.REACT_APP_SIGNIN_PAGE_COMPLIANCE_EMAIL}
                    </a>
                  </li>
                )}
                {process.env.REACT_APP_SIGNIN_PAGE_PHONE && (
                  <li>
                    <FontAwesomeIcon icon={["fas", "phone-volume"]} /> Phone:{" "}
                    <a
                      href={`tel:${process.env.REACT_APP_SIGNIN_PAGE_PHONE.replaceAll(
                        /\s/g,
                        ""
                      ).trim()}`}
                    >
                      {process.env.REACT_APP_SIGNIN_PAGE_PHONE}
                    </a>
                  </li>
                )}
              </ul>
            </div>
            <div className="footer-company-info d-flex justify-content-center">
              <FooterSpan />
            </div>
          </div>
        </div>
      </Aux>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    newUser: (userId) => {
      dispatch(newUser(userId));
    },
    createUser: (objValues) => {
      dispatch(createUser(objValues));
    },
    dismissError: () => {
      dispatch(dismissError());
    },
    setLoading: () => {
      dispatch(setLoading());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterUser);
