import React from 'react';
import $ from 'jquery';

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const DashboardDefault = React.lazy(() => import('./Containers/Tenant/Dashboard'));
const Products = React.lazy(() => import('./Containers/Tenant/Products'));
const Transactions = React.lazy(() => import('./Containers/Tenant/Transactions'));
const Recipients = React.lazy(() => import('./Containers/Tenant/Recipients'));

const SendMoneyForm = React.lazy(() => import('./Containers/Tenant/SendMoney/SendMoneyForm'));

// const PersonalInfo = React.lazy(() => import('./Containers/Tenant/Settings/PersonalInfo'));
// const Accounts = React.lazy(() => import('./Containers/Tenant/Settings/Accounts'));
// const Notifications = React.lazy(() => import('./Containers/Tenant/Settings/Notifications'));
const UserPending = React.lazy(() => import('./App/components/UserPending'));

const routes = [
    { path: '/', exact: true, name: 'Default', component: DashboardDefault },
    { path: '/home', exact: true, name: 'Default', component: DashboardDefault },
    { path: '/dashboard/sendmoney', exact: true, name: 'Send Money', component: SendMoneyForm },
    { path: '/transactions', exact: true, name: 'Recipients', component: Transactions },
    { path: '/Products', exact: true, name: 'Recipients', component: Products },
    { path: '/recipients', exact: true, name: 'Recipients', component: Recipients },
    { path: '/recipients/:id', exact: true, name: 'Recipient Detail', component: Recipients },

    // { path: '/personalinfo', exact: true, name: 'Personal Info', component: PersonalInfo },
    // { path: '/carddetails', exact: true, name: 'Cards & Bank Accounts', component: Accounts },
    // { path: '/notifications', exact: true, name: 'Cards & Bank Accounts', component: Notifications },
    { path: '/user-pending', exact: true, name: 'User Pending', component: UserPending },
];

export default routes;
