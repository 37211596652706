const initState = {
  list: [],
};

export default function banks(state = initState, action) {
  switch (action.type) {
    case "FETCH_BANKS_DATA":
      return {
        ...state,
        list: action.list,
      };

    case "LOGOUT":
      return initState;

    default:
      return state;
  }
}
