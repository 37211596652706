/**
 * src/api/http.js
 */
import axios from 'axios'
import qs from 'qs'
import { getAuthHeader } from '../actions/auth'
import Config from '../../config'
import uuidv1 from 'uuid/v1'

/**
 *
 * parse error response
 */
function parseError (messages) {
  // error

  if (messages) {
    if (messages instanceof Array) {
      let strError = '';
      messages.forEach((error) => {
        strError += error.title + '. ';
      })
      return Promise.reject({ messages: strError })
    } else {
      //general error message
      if(messages != null)
        return Promise.reject({ messages: messages.message })

      return Promise.reject({ messages: 'There was a problem in connecting to the server. Please check your internet and try again.' })
    }
  } else {
    //general error message
    return Promise.reject({ messages: 'There was a problem in connecting to the server. Please check your internet and try again.' })
  }
}

/**
 * parse response
 */
function parseBody (response) {

  if (response.status === 200 ) {
    return response
  } else if(response.status === 201){
    return response
  } else if(response.status === 400){
    return this.parseError(response.data.errors)
  } else if(response.status === 202){
    return response
  } else if(response.status === 401){
    return response
  }
  else {
    return this.parseError(response.data.errors)
  }
}

/**
 * axios instance
 */
let instance = axios.create({
  paramsSerializer: function (params) {
    return qs.stringify(params, { encode: false })
  }
})

// request header
instance.interceptors.request.use((config) => {
  // Simply add the token on the headers when getting data

  let finalHeaders = {}

  let apiToken = getAuthHeader(true);
  if(apiToken){
    finalHeaders['Authorization'] = getAuthHeader(true)
  }

  if(config.url.includes(Config.REACT_APP_WALLET_CALCULATION_URI) ){
    finalHeaders['Content-Type'] = 'application/json'
    finalHeaders['x-idempotency-key'] =  uuidv1()
  }

  config.headers = finalHeaders


  return config
}, error => {
  return Promise.reject(error)
})

// response parse
instance.interceptors.response.use((response) => {
  return parseBody(response)
}, error => {
  console.warn('Error status', error.response.status)
  // return Promise.reject(error)

  //TODO: add refresh token when token is expired

  if (error.response) {

    /*
      Temporary catch unauthorized request, refresh page to automatically redirected to login
     */
    if(parseInt(error.response.status, 10) === 401){
      //window.location.reload();
    }

    return parseError(error.response.data.errors)

  } else {
    return Promise.reject(error)
  }
})

const http = instance
export default http
