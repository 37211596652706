import React, { Component, Suspense } from "react";
import { Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Loadable from "react-loadable";
import Notification from "./components/Notification";

import "../../node_modules/font-awesome/scss/font-awesome.scss";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import Loader from "./layout/Loader";
import Aux from "../hoc/_Aux";
import ScrollToTop from "./layout/ScrollToTop";

import AuthRouter from "../route";
import { getChannels } from "../store/actions/channels";
import { getTypes } from "../store/actions/types";
import { getCountryData } from "../store/actions/countries";
import { fetchStatusList } from "../store/actions/status";

import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";

// import './../assets/scss/style.scss';

const AdminLayout = Loadable({
  loader: () => import("./layout/AdminLayout"),
  loading: Loader,
});

class App extends Component {
  //build cookie support
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      isPending: false,
      isAuthenticating: true,
      pageName: "Loading...",
    };
    library.add(fas);
    require(`./../assets/scss/tenants/${process.env.REACT_APP_TENANT}/style.scss`);
  }

  async componentDidMount() {
    try {
      //find the session on sessionStorage
      if (
        sessionStorage.getItem("usersession") &&
        sessionStorage.getItem("userinfo")
      ) {
        let userSession = JSON.parse(sessionStorage.getItem("usersession"));
        let userInfo = JSON.parse(sessionStorage.getItem("userinfo"));
        let userTenant = JSON.parse(sessionStorage.getItem("usertenant"));

        this.props.updateLoginFromSession({
          user: userInfo,
          auth: userSession,
          tenant: userTenant,
        });

        this.userHasAuthenticated(true, userInfo.account.status !== 8);
        //this.props.fetchCurrUser(userSession.id) //fetch the current user
      }
    } catch (e) {
      if (e !== "No current user") {
        alert(e);
      }
    }

    //set authentication here...
    this.setState({ isAuthenticating: false });
  }

  componentDidUpdate(prevProps) {
    //set redirection into tenant when it is home and accesstoken is already created
    let { accessToken } = this.props.auth;
    if (accessToken !== null) {
      if (prevProps.auth.accessToken !== accessToken) {
        this.props.loadInitialList();
      }
    }
  }

  userHasAuthenticated = (authenticated, isPending) => {
    this.setState({ isAuthenticated: authenticated, isPending: isPending });
  };

  setPageName = (title) => {
    this.setState({ pageName: title });
  };

  handleLogout = (event) => {
    //clear session storage
    sessionStorage.clear();

    this.userHasAuthenticated(false);

    //redirect to login page
    this.props.history.push("/login");
  };

  render() {
    document
      .querySelector("#remittance-favicon")
      .setAttribute(
        "href",
        "/assets/tenants/" +
          process.env.REACT_APP_TENANT.toLowerCase() +
          "/favicon.ico"
      );

    document
      .querySelector("#remittance-manifest")
      .setAttribute(
        "href",
        "/assets/tenants/" +
          process.env.REACT_APP_TENANT.toLowerCase() +
          "/manifest.json"
      );

    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      isPending: this.state.isPending,
      userHasAuthenticated: this.userHasAuthenticated,
    };

    const headerProps = {
      handleLogout: this.handleLogout,
    };

    if (!this.state.isAuthenticating && this.state.isAuthenticated) {
      return (
        <Aux>
          <ScrollToTop>
            <Suspense fallback={<Loader />}>
              <Switch>
                {/* <Route path="/" render={(props) => (<AdminLayout AppProps={headerProps} childProps={childProps} {...props}/>)} /> */}
                <AdminLayout
                  AppProps={headerProps}
                  childProps={childProps}
                  {...this.props}
                />
              </Switch>
            </Suspense>
          </ScrollToTop>

          <Notification />
        </Aux>
      );
    } else {
      return (
        <Aux>
          <AuthRouter childProps={childProps} {...this.props} />
          <Notification />
        </Aux>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateLoginFromSession: (data) => {
      dispatch({
        type: "LOGIN",
        ...data,
      });
    },
    loadInitialList: () => {
      dispatch(getChannels());
      dispatch(getTypes());
      dispatch(getCountryData());
      dispatch(fetchStatusList());
    },
  };
};

export default withCookies(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
);
