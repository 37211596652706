import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { connect } from 'react-redux';

import 'react-toastify/dist/ReactToastify.css';

class Notification extends Component{

   generateNotification(notification){
       switch (notification.type.toUpperCase()) {
           case "SUCCESS":
               return toast.success(notification.message);
           case "INFO":
               return toast.info(notification.message);
           case "ERROR":
               return toast.error(notification.message);
           case "WARNING":
               return toast.warning(notification.message);
           default:
               return null
       }
   }

   componentWillReceiveProps(newProps){
       Object.keys(newProps.notification).map((notificationId) => {
           return this.generateNotification(newProps.notification[notificationId]);
       });
   }

   render(){
       return(
           <ToastContainer position={toast.POSITION.TOP_RIGHT}
            autoClose={10000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
           />
       )
   }
}

const mapStateToProps = (state) => {
    return {
        notification: state.notification
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        dissmissNotif: () => {
            //general dimisser for loading states
            dispatch({
                type: 'NOTIFICATION_DISSMISS'
            })

        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
