const initState = {
  open: false,
  loading: false,
  loadingText: "Loading...",
  error: false,
  errorMessage: "",
  title: "",
  content: [],
};

export default function reactmodal(state = initState, action) {
  switch (action.type) {
    case "SHOW_REACT_MODAL":
      return {
        ...state,
        open: true,
        title: action.title,
        content: action.content,
      };

    case "HIDE_REACT_MODAL":
      return {
        ...initState,
      };

    case "SHOW_REACT_MODAL_LOADER":
      return {
        ...state,
        loading: true,
        loadingText: "Please wait, while we process your request...",
      };

    case "REACT_MODAL_ERROR":
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case "LOGOUT":
      return initState;

    default:
      return state;
  }
}
