import Jsona from "jsona";
import axios from "axios";
import http from "../api/http";
// import Constant from "../constant";
import uuidv1 from "uuid/v1";

import { showNotification } from "./notification";
import { getAuthHeader } from "./auth";

import fetchIntercept from "fetch-intercept";

import _ from "lodash";

export function fetchRates(currency) {
  let apiBaseUrl =
    process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_RATES_LIST;

  let params = {
    params: {
      baseCurrency: currency,
    },
  };

  const request = axios.get(apiBaseUrl, params);

  return (dispatch) => {
    request.then(
      ({ data }) => {
        if (data.quotes && data.quotes.length > 0) {
          data.quotes = _.uniqBy(data.quotes, "receivingCurrency");
          data.quotes = _.orderBy(data.quotes, ["receivingCurrency"], ["asc"]);
        }

        dispatch({ type: "LIST_RATES", payload: data });
        dispatch({ type: "HIDE_LOADER" });
      },
      (error) => {
        if (error.response) {
          dispatch({
            type: "SEND_MONEY_RATES_ERROR",
            errorMsg: error.response.data.errors.message
              ? error.response.data.errors.message
              : "Fetching rates failed. Please try again later.",
          });

          dispatch(
            showNotification({
              type: "error",
              message: error.response.data.errors.message
                ? error.response.data.errors.message
                : "Fetching rates failed. Please try again later.",
            })
          );
        } else if (error.messages) {
          dispatch({
            type: "SEND_MONEY_RATES_ERROR",
            errorMsg: error.messages,
          });
          dispatch(
            showNotification({
              type: "error",
              message: error.messages,
            })
          );
        } else {
          dispatch({
            type: "SEND_MONEY_RATES_ERROR",
            errorMsg: "Fetching rates failed. Please try again later.",
          });
          dispatch(
            showNotification({
              type: "error",
              message: "Fetching rates failed. Please try again later.",
            })
          );
        }
      }
    );
  };
}

export function selectProduct() {
  return (dispatch) => {
    dispatch({
      type: "SEND_MONEY_PRODUCT",
    });
  };
}

export function selectRecipient(details) {
  return (dispatch) => {
    dispatch({
      type: "SEND_MONEY_RECIPIENT",
      payload: details,
    });
  };
}

export function addTransactionDetails(details) {
  return (dispatch) => {
    dispatch({
      type: "SEND_MONEY_TRANSACTION",
      payload: details,
    });
  };
}

export function requestQuote(quotation, confirm = false, isSendAgain = false) {
  let apiBaseUrl =
    process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_QUOTE;

  let params = {
    data: {
      type: "Quote",
      attributes: {
        ...quotation,
      },
    },
  };

  const request = http.post(apiBaseUrl, params);

  const dataFormatter = new Jsona();

  return (dispatch) => {
    request.then(
      ({ data }) => {
        let res = dataFormatter.deserialize(data);

        dispatch({
          type: "REQUEST_QUOTATION",
          isSendAgain,
          payload: {
            ...res,
            amount: quotation.amount,
            issending: quotation.issending,
          },
        });

        if (confirm) dispatch({ type: "CONFIRM_QUOTATION" });
      },
      (error) => {
        dispatch({
          type: "SEND_MONEY_QUOTE_ERROR",
          errorMessage: error.messages,
        });

        dispatch(
          showNotification({
            type: "error",
            message: error.messages,
          })
        );
      }
    );
  };
}

export function confirmRates(quotationId, quote = null) {
  let apiBaseUrl =
    process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_QUOTE;
  apiBaseUrl = apiBaseUrl.concat("/", quotationId);

  const request = http.patch(apiBaseUrl);

  return (dispatch) => {
    request.then(
      ({ data }) => {
        if (quote)
          dispatch({ type: "REQUEST_CONFIRM_QUOTATION", payload: quote });
        else dispatch({ type: "CONFIRM_QUOTATION" });
      },
      (error) => {
        dispatch({
          type: "SEND_MONEY_QUOTE_ERROR",
          errorMessage: error.messages,
        });

        dispatch(
          showNotification({
            type: "error",
            message: error.messages,
          })
        );
      }
    );
  };
}

export function sendRemittance(remittance, paymentMethodId, ccObj) {
  let apiBaseUrl =
    process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_REMITTANCE;

  let params = {
    data: {
      type: "remittance",
      attributes: {
        ...remittance,
      },
    },
  };

  const request = http.post(apiBaseUrl, params);

  const dataFormatter = new Jsona();

  return (dispatch) => {
    dispatch({
      type: "OTP_LOADING",
    });

    request.then(
      ({ data }) => {
        let res = dataFormatter.deserialize(data);

        dispatch({
          type: "OTP_RECEIVED",
          payload: res,
          formData: { remittance, paymentMethodId, ccObj },
        });
      },
      (error) => {
        dispatch({
          type: "SEND_MONEY_OTP_ERROR",
          errorMessage: error.messages,
        });

        console.log("error.errormessages", error.messages);
        if (error.messages.toLowerCase().includes("compliance")) {
          //TODO: Add sweet alert
          dispatch(toggleComplianceError(error.messages, true));
        } else {
          dispatch(
            showNotification({
              type: "error",
              message: error.messages,
            })
          );
        }
      }
    );
  };
}

export function toggleComplianceError(
  errorMessage = "Compliance Error",
  boolShow = false
) {
  return (dispatch) => {
    dispatch({
      type: "SEND_MONEY_COMPLIANCE_ERROR",
      boolHasError: boolShow,
      errorMessage: errorMessage,
    });
  };
}

export function resendOTP(transactionId) {
  let confirmUrl =
    process.env.REACT_APP_IDENTITY_BASE_URL +
    process.env.REACT_APP_REMITTANCE_RESEND_OTP;
  confirmUrl += "/" + transactionId;

  const request = http.get(confirmUrl);

  return (dispatch) => {
    request.then(
      ({ data }) => {
        dispatch(
          showNotification({
            type: "success",
            message:
              "OTP has successfully been sent to your registered mobile and email address.",
          })
        );
      },
      (error) => {
        dispatch({
          type: "SEND_MONEY_OTP_ERROR",
          errorMessage: error.messages,
        });
        if (error.messages.search("resend") > -1) {
          dispatch(
            showNotification({
              type: "error",
              message: error.messages,
            })
          );
        }
      }
    );
  };
}

export function validateOTP(
  transactionId,
  objFormData,
  paymentMethodId,
  otp,
  ccObj
) {
  return (dispatch) => {
    if (otp === null || otp === "" || otp.length < 6) {
      dispatch({ type: "INVALID_OTP", entered_otp: otp });
    } else
      dispatch(
        verifyOTP(transactionId, objFormData, paymentMethodId, otp, ccObj)
      );
  };
}

export function verifyOTP(
  transactionId,
  objFormData,
  paymentMethodId,
  otp,
  ccObj
) {
  let verifyOTPParams = {
    data: {
      type: "transaction",
      attributes: {
        Otp: otp,
      },
    },
  };

  let verifyOTPUrl =
    process.env.REACT_APP_IDENTITY_BASE_URL +
    process.env.REACT_APP_REMITTANCE_CONFIRM_OTP;

  const request = http.patch(verifyOTPUrl, verifyOTPParams);
  const dataFormatter = new Jsona();

  return (dispatch) => {
    request.then(
      ({ data }) => {
        let res = dataFormatter.deserialize(data);

        switch (paymentMethodId) {
          case 32:
          case "32":
            dispatch({ type: "OTP_SUCCESS" });
            break;
          case 128:
          case "128":
            dispatch(makeCardPayment(res.id, ccObj));
            break;

          default:
            dispatch({ type: "REMITTANCE_SUCCESS_CONFIRMATION" });
            dispatch(
              showNotification({
                type: "success",
                message:
                  "Your transaction has successfully been executed. Thank You!",
              })
            );
            break;
        }
      },
      (error) => {
        dispatch({
          type: "SEND_MONEY_OTP_ERROR",
          errorMessage: error.messages,
        });
      }
    );
  };
}

export function updateCardReference(transactionId, cardPaymentReference) {
  let updateCardReferenceParams = {
    data: {
      type: "Transaction",
      attributes: {
        cardPaymentReference,
      },
    },
  };

  let updateCardReferenceUrl =
    process.env.REACT_APP_IDENTITY_BASE_URL +
    process.env.REACT_APP_REMITTANCE_UPDATE_CARD_REFERENCE +
    "/" +
    transactionId;

  const request = http.patch(updateCardReferenceUrl, updateCardReferenceParams);
  // const dataFormatter = new Jsona();

  return (dispatch) => {
    request.then(
      ({ data }) => {
        dispatch({ type: "REMITTANCE_SUCCESS_CONFIRMATION" });
        dispatch(
          showNotification({
            type: "success",
            message:
              "Your transaction has successfully been executed. Thank You!",
          })
        );
      },
      (error) => {
        dispatch({
          type: "SEND_MONEY_ERROR",
          errorMessage:
            "Trust Payment was not accepted to system! Please contact administrator.",
        });
        dispatch(
          showNotification({
            type: "error",
            message:
              "Trust Payment was not accepted to system! Please contact administrator.",
          })
        );
      }
    );
  };
}

const unregister = fetchIntercept.register({
  request: function (url, config) {
    // Modify the url or config here

    // console.log("request", config);
    // console.log("request", url);

    return [url, config];
  },

  requestError: function (error) {
    // Called when an error occured during another 'request' interceptor call

    // console.log("requestError", error);

    return Promise.reject(error);
  },

  response: function (response) {
    // Modify the reponse object

    // console.log("response", response);
    // console.log("response", response.url);
    // console.log("response", response.headers);
    // console.log("response", response.headers.get("Location"));
    // console.log("custom header", response.headers.get("X-Custom-header"));

    response.headers.forEach(function (val, key) {
      // console.log(key + " -> " + val);
    });

    return response;
  },

  responseError: function (messages) {
    // Handle an fetch error
    console.info("responseError", messages);

    if (messages) {
      if (messages instanceof Array) {
        let strError = "";
        messages.forEach((error) => {
          strError += error.title + ". ";
        });
        return Promise.reject({ messages: strError });
      } else {
        //general error message
        if (messages != null)
          return Promise.reject({ messages: messages.message });

        return Promise.reject({
          messages:
            "There was a problem in connecting to the server. Please check your internet and try again.",
        });
      }
    } else {
      //general error message
      return Promise.reject({
        messages:
          "There was a problem in connecting to the server. Please check your internet and try again.",
      });
    }
  },
});

export function captureCardPayment(id, ccObj) {
  let paymentUrl =
    process.env.REACT_APP_IDENTITY_BASE_URL +
    process.env.REACT_APP_REMITTANCE_CARDPAYMENT;

  let params = {
    data: {
      type: "cardpayment",
      attributes: {
        transactionId: id,
        ...ccObj,
      },
    },
  };

  fetch(paymentUrl, {
    method: "POST",
    redirect: "manual",
    headers: new Headers({
      Authorization: getAuthHeader(true),
      "Content-Type": "application/json",
      "x-idempotency-key": uuidv1(),
    }),
    body: JSON.stringify(params),
  });

  unregister();
}

export function makeCardPayment(id, ccObj) {
  let apiBaseUrl =
    process.env.REACT_APP_IDENTITY_BASE_URL +
    process.env.REACT_APP_REMITTANCE_CARDPAYMENT;

  let params = {
    data: {
      type: "cardpayment",
      attributes: {
        transactionId: id,
        ...ccObj,
      },
    },
  };

  const request = http.post(apiBaseUrl, params);

  const dataFormatter = new Jsona();

  return (dispatch) => {
    request.then(
      ({ data }) => {
        let res = dataFormatter.deserialize(data);
        //let secureUrl = res.redirectUrl.replace('http','https');
        window.location.href = res.redirectUrl;

        //   dispatch({ type: 'REMITTANCE_SUCCESS_CONFIRMATION' })
        //   dispatch(showNotification({ type: 'success', message: "Your transaction has successfully been executed. Thank You!" }));
      },
      (error) => {
        if (error.response) {
          dispatch({
            type: "SEND_MONEY_ERROR",
            errorMessage: error.response.data.errors.message
              ? error.response.data.errors.message
              : "Card payment failed. Please try again later.",
          });

          dispatch(
            showNotification({
              type: "error",
              message: error.response.data.errors.message
                ? error.response.data.errors.message
                : "Card payment failed. Please try again later.",
            })
          );
        } else if (error.messages) {
          dispatch({
            type: "SEND_MONEY_ERROR",
            errorMessage: error.messages,
          });
          dispatch(
            showNotification({
              type: "error",
              message: error.messages,
            })
          );
        } else {
          dispatch({
            type: "SEND_MONEY_ERROR",
            errorMessage: "Card payment failed. Please try again later.",
          });
          dispatch(
            showNotification({
              type: "error",
              message: "Card payment failed. Please try again later.",
            })
          );
        }
      }
    );
  };
}

export function sendMoneyLoading() {
  return (dispatch) => {
    dispatch({
      type: "SEND_MONEY_LOADING",
    });
  };
}

export function sendMoneyHideLoading() {
  return (dispatch) => {
    dispatch({
      type: "SEND_MONEY_LOADING",
    });
  };
}

export function resetSendMoneyForm() {
  return (dispatch) => {
    dispatch({
      type: "RESET_SEND_MONEY",
    });
  };
}

export function displaySendMoneyError(errorMessage) {
  return (dispatch) => {
    dispatch(
      showNotification({
        type: "error",
        message: errorMessage,
      })
    );
  };
}
