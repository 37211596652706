import Jsona from "jsona";
import http from "../api/http";

export function fetchStatusList() {
  let apiBaseUrl =
    process.env.REACT_APP_IDENTITY_BASE_URL +
    process.env.REACT_APP_STATUS_LIST_URI;

  const request = http.get(apiBaseUrl);

  const dataFormatter = new Jsona();

  return (dispatch) => {
    request.then(
      ({ data }) => {
        let res = dataFormatter.deserialize(data);

        dispatch({
          type: "LIST_STATUS",
          payload: res,
        });
      },
      (error) => {}
    );
  };
}
