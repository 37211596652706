import Config from "./../../config";

const initState = {
  data: [],
  loadingSearch: false,
  selectedUser: {
    fullname: null,
    firstname: null,
    middlename: null,
    lastname: null,
    birthdate: null,
    gender: null,
    nationality: null,
    account: {
      accountId: null,
      created: null,
      expirationDate: null,
      groups: null,
      id: null,
      roles: null,
      status: null,
      type: null,
      username: null,
    },
    photo: {
      id: null,
      type: null,
      url: null,
    },
    document: [],
    phonenumber: [],
    email: [],
    wallet: [],
  },
  tempDocs: {},
  kycLoading: false,
  hasSelectedUser: false,
  meta: Config.defaultMeta,
  error: false,
  errorMsg: "",
  loading: false,
  loading_image: false,
};

export default function users(state = initState, action) {
  switch (action.type) {
    case "FETCH_USERS":
      return {
        ...state,
        error: false,
        data: action.payload,
        meta: action.meta,
        loading: false,
        loading_image: false,
        loadingSearch: false,
      };

    case "RESET_USER_LIST":
      return {
        ...state,
        error: false,
        data: [],
        loading: false,
        loading_image: false,
        loadingSearch: false,
      };

    case "NEW_USER":
      return {
        ...state,
        loading: false,
        loading_image: false,
        userCreated: false,
        selectedUser: {
          ...initState.selectedUser,
        },
      };

    case "FETCH_USER":
      return {
        ...state,
        error: false,
        selectedUser: {
          id: action.payload.id,
          fullname: action.payload.Fullname,
          wallet: action.payload.wallet || null,
          firstname: action.payload.Firstname,
          middlename: action.payload.Middlename,
          lastname: action.payload.Lastname,
          birthdate: action.payload.BirthDate,
          gender: action.payload.Gender,
          nationality: action.payload.Nationality,
          account: { ...action.payload.account },
          photo: { ...action.payload.photo },
          document: action.payload.document,
          phonenumber: action.payload.phonenumber,
          email: action.payload.email,
        },
        userCreated: false,
        loading: false,
        loading_image: false,
      };

    case "USER_CREATED":
      return {
        ...state,
        error: false,
        selectedUser: {
          id: action.payload.id,
          fullname: action.payload.Fullname,
          wallet: action.payload.wallet || null,
          firstname: action.payload.Firstname,
          middlename: action.payload.Middlename,
          lastname: action.payload.Lastname,
          birthdate: action.payload.BirthDate,
          gender: action.payload.Gender,
          nationality: action.payload.Nationality,
          account: { ...action.payload.account },
          photo: { ...action.payload.photo },
          document: action.payload.document,
          phonenumber: action.payload.phonenumber,
          email: action.payload.email,
        },
        userCreated: true,
        loading: false,
        loading_image: false,
      };

    case "USER_UPDATED":
      return {
        ...state,
        error: false,
        selectedUser: {
          id: action.payload.id,
          fullname: action.payload.Fullname,
          wallet: action.payload.wallet || null,
          firstname: action.payload.Firstname,
          middlename: action.payload.Middlename,
          lastname: action.payload.Lastname,
          birthdate: action.payload.BirthDate,
          gender: action.payload.Gender,
          nationality: action.payload.Nationality,
          account: { ...action.payload.account },
          photo: { ...action.payload.photo },
          document: action.payload.document,
          phonenumber: action.payload.phonenumber,
          email: action.payload.email,
        },
        userUpdated: true,
        loading: false,
        loading_image: false,
      };

    case "USER_LOADING":
      return {
        ...state,
        error: false,
        loading: true,
        loading_image: false,
      };

    case "ERROR_USERS":
      return {
        ...state,
        error: true,
        errorMsg: action.errorMsg,
        loading: false,
        loading_image: false,
      };

    case "DISMISS_USER_ERROR":
      return {
        ...state,
        error: false,
        errorMsg: "",
        loading: false,
        loading_image: false,
      };

    case "LOADING_IMAGE":
      return {
        ...state,
        loading: false,
        loading_image: true,
      };

    case "LOADING_KYC":
      return {
        ...state,
        kycLoading: true,
        loading_image: true,
      };

    case "KYC_DROP_FILE":
      let newDocs = { ...state.tempDocs };
      newDocs[action.name] = {
        name: action.name,
        file: action.file,
        preview: URL.createObjectURL(action.file),
      };
      return {
        ...state,
        tempDocs: newDocs,
      };

    case "USER_IMAGE_UPDATED":
      return {
        ...state,
        selectedUser: {
          ...state.selectedUser,
          photo: {
            ...state.selectedUser.photo,
            url: action.photoUrl,
          },
        },
        error: false,
        errorMsg: "",
        loading: false,
        loading_image: false,
      };

    case "USER_DOCUMENT_UPDATED":
      return {
        ...state,
        loading: false,
        loading_image: true,
        kycLoading: false,
      };

    case "SHOW_USER_LOADING_SEARCH":
      return {
        ...state,
        loading: false,
        loadingSearch: true,
      };

    case "HIDE_USER_LOADING_SEARCH":
      return {
        ...state,
        loading: false,
        loadingSearch: false,
      };

    case "LOGOUT":
      return initState;

    default:
      return state;
  }
}
