const initState = {
  defaultSelectedTypes: "",
  list: [],
};

export default function types(state = initState, action) {
  switch (action.type) {
    case "LIST_TYPES":
      return {
        ...state,
        list: action.types,
      };

    case "LOGOUT":
      return initState;

    default:
      return state;
  }
}
