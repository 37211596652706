const initState = {
  defaultSelectedRole: "",
  list: [],
};

export default function status(state = initState, action) {
  switch (action.type) {
    case "LIST_STATUS":
      return {
        ...state,
        list: action.payload,
      };

    case "LOGOUT":
      return initState;

    default:
      return state;
  }
}
