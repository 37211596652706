import Jsona from "jsona";
import http from "../api/http";
import Config from "../../config";
import Constant from "../constant";

import { fetchUpdatedUserProfile } from "./auth";
import { showNotification } from "./notification";

const defaultParams = {
  include: Config.REACT_APP_USERS_INCLUDE,
};

export function fetchUsers(strFilterValue) {
  if (strFilterValue === "") {
    return (dispatch) => {
      dispatch({
        type: "RESET_USER_LIST",
      });
    };
  }

  let apiBaseUrl =
    process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_USERS_URI;

  let objFilter = null;

  if (strFilterValue) {
    let tempFilterObj = {
      "email.value,phonenumber.value": "'" + strFilterValue + "'",
    };

    objFilter = tempFilterObj;
  }

  let params = strFilterValue
    ? {
        ...defaultParams,
        filter: objFilter,
      }
    : {
        ...defaultParams,
        // ...Config.defaultMeta
      };

  const request = http.get(apiBaseUrl, {
    params,
  });

  const dataFormatter = new Jsona();

  return (dispatch) => {
    request.then(
      ({ data }) => {
        let res = dataFormatter.deserialize(data);

        dispatch({
          type: "FETCH_USERS",
          meta: data.meta,
          payload: res,
        });
      },
      (error) => {
        dispatch({
          type: "ERROR_USERS",
          errorMsg: Constant.GENERIC_ERROR_MSG,
        });
      }
    );
  };
}

export function resetUserList() {
  return (dispatch) => {
    dispatch({
      type: "RESET_USER_LIST",
    });
  };
}

export function newUser() {
  return (dispatch) => {
    dispatch({
      type: "NEW_USER",
    });
  };
}

export function fetchUser(userId) {
  let apiBaseUrl =
    process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_USER_URI;

  apiBaseUrl += userId;

  let params = {
    params: {
      include: Config.REACT_APP_USERS_INCLUDE,
    },
  };

  const request = http.get(apiBaseUrl, params);

  const dataFormatter = new Jsona();

  return (dispatch) => {
    request.then(
      ({ data }) => {
        let res = dataFormatter.deserialize(data);

        dispatch({
          type: "FETCH_USER",
          payload: res,
        });
      },
      (error) => {
        dispatch({
          type: "ERROR_USERS",
          errorMsg: Constant.GENERIC_ERROR_MSG,
        });
      }
    );
  };
}

export function setLoading() {
  return (dispatch) => {
    dispatch({
      type: "USER_LOADING",
    });
  };
}

export function createUser(objFormValues) {
  let apiBaseUrl =
    process.env.REACT_APP_IDENTITY_BASE_URL +
    process.env.REACT_APP_USER_CREATE_URI;

  let params = {
    data: {
      type: "User",
      attributes: {
        ...objFormValues,
        tenant: Config.REACT_APP_TENANT,
      },
    },
  };

  const request = http.post(apiBaseUrl, params);

  const dataFormatter = new Jsona();

  return (dispatch) => {
    request.then(
      ({ data }) => {
        let res = dataFormatter.deserialize(data);

        dispatch({
          type: "USER_CREATED",
          payload: res,
        });

        dispatch(
          showNotification({
            type: "success",
            message:
              "Thank you for filling out your information! We’ve sent you an email to set your personalize password.",
          })
        );
      },
      (error) => {
        dispatch({
          type: "ERROR_USERS",
          errorMsg: error.messages,
        });

        dispatch(showNotification({ type: "error", message: error.messages }));
      }
    );
  };
}

export function updateUser(userId, objFormValues) {
  let apiBaseUrl =
    process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_USERS_URI;

  apiBaseUrl += "/" + userId;

  let params = {
    data: {
      type: "User",
      attributes: {
        ...objFormValues,
      },
    },
  };

  const request = http.patch(apiBaseUrl, params);
  const dataFormatter = new Jsona();

  return (dispatch) => {
    request.then(
      ({ data }) => {
        let res = dataFormatter.deserialize(data);
        dispatch({
          type: "USER_UPDATED", // instead of created dispatch for update
          payload: res,
        });

        dispatch(
          showNotification({
            type: "success",
            message: "User Profile Successfully Updated",
          })
        );

        //dispatch user update here...
        dispatch(fetchUpdatedUserProfile());
      },
      (error) => {
        dispatch({
          type: "ERROR_USERS",
          errorMsg: error.messages,
        });
      }
    );
  };
}

export function dismissError() {
  return (dispatch) => {
    dispatch({
      type: "DISMISS_USER_ERROR",
    });
  };
}

export function uploadUserProfilePic(userId, picture) {
  let apiBaseUrl =
    process.env.REACT_APP_IDENTITY_BASE_URL +
    process.env.REACT_APP_IMAGE_UPLOAD_URI;

  apiBaseUrl += userId;

  var formData = new FormData();

  formData.append("file", picture.pop());

  //build form header
  http.interceptors.request.use((config) => {
    config.headers = { "Content-Type": "multipart/form-data" };
    return config;
  });

  const request = http.post(apiBaseUrl, formData);

  return (dispatch) => {
    request.then(
      ({ data }) => {
        dispatch({
          type: "USER_IMAGE_UPDATED",
          photoUrl: data.data.attributes.url,
        });

        dispatch(fetchUpdatedUserProfile());
      },
      (error) => {
        dispatch({
          type: "ERROR_USERS",
          errorMsg: error.messages,
        });
      }
    );
  };
}

export function setImageLoading() {
  return (dispatch) => {
    dispatch({
      type: "LOADING_IMAGE",
    });
  };
}

export function onDropFile(file, name) {
  return (dispatch) => {
    dispatch({
      type: "KYC_DROP_FILE",
      name,
      file,
    });
  };
}

export function uploadDocuments(
  userId,
  documentType,
  documentNumber,
  expiryDate,
  objData,
  userStatus
) {
  let apiBaseUrl =
    process.env.REACT_APP_IDENTITY_BASE_URL +
    process.env.REACT_APP_DOCUMENT_UPLOAD_URI;

  apiBaseUrl += userId;

  var formData = new FormData();

  formData.append("expiryDate", expiryDate);
  formData.append("documentType", documentType);
  formData.append("documentNumber", documentNumber);

  for (var key in objData) {
    let row = objData[key].file;
    if (typeof row === "string") {
      formData.append("files", row);
    } else if (typeof row === "object") {
      formData.append("files", row);
    }
  }

  //build form header
  http.interceptors.request.use((config) => {
    config.headers = { "Content-Type": "multipart/form-data" };
    return config;
  });

  const request = http.post(apiBaseUrl, formData);

  return (dispatch) => {
    request.then(
      ({ data }) => {
        dispatch(
          showNotification({
            type: "success",
            message: "User KYC document uploaded!",
          })
        );
        dispatch({
          type: "USER_DOCUMENT_UPDATED",
          photoUrl: data.data.attributes.url,
        });

        if (userStatus === 256) {
          dispatch(updateUserStatus(userId, -256));
        } else {
          dispatch(fetchUpdatedUserProfile());
        }
      },
      (error) => {
        dispatch({
          type: "ERROR_USERS",
          errorMsg: error.messages,
        });
      }
    );
  };
}

export function updateUserStatus(userId, status) {
  let apiBaseUrl =
    process.env.REACT_APP_IDENTITY_BASE_URL +
    process.env.REACT_APP_USERS_ACCOUNT_URI;

  apiBaseUrl += "/" + userId;

  let params = {
    data: {
      type: "User",
      attributes: {
        status,
        noEmailSending: true,
      },
    },
    include: Config.REACT_APP_PROFILE_INCLUDE,
  };

  const request = http.patch(apiBaseUrl, params);
  // const dataFormatter = new Jsona();

  return (dispatch) => {
    request.then(
      ({ data }) => {
        dispatch(fetchUpdatedUserProfile());
      },
      (error) => {
        dispatch({
          type: "ERROR_USERS",
          errorMsg: error.messages,
        });
        dispatch(
          showNotification({
            type: "error",
            message: `Something went wrong during account update. Please contact ${process.env.REACT_APP_TENANT_TITLE} Admin.`,
          })
        );
      }
    );
  };
}

export function showSearchLoading() {
  return (dispatch) => {
    dispatch({
      type: "SHOW_USER_LOADING_SEARCH",
    });
  };
}

export function hideSearchLoading() {
  return (dispatch) => {
    dispatch({
      type: "HIDE_USER_LOADING_SEARCH",
    });
  };
}
