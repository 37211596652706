import React from "react";

export default class FooterSpan extends React.Component {
  render() {
    return (
      <span>
        {process.env.REACT_APP_FOOTER_ABOUT && (
          <span>
            <a
              href={
                process.env.REACT_APP_FOOTER_ABOUT
                  ? process.env.REACT_APP_FOOTER_ABOUT
                  : "/"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              About
            </a>
          </span>
        )}
        {process.env.REACT_APP_FOOTER_CONTACTUS && (
          <span>
            {" "}
            |{" "}
            <a
              href={
                process.env.REACT_APP_FOOTER_CONTACTUS
                  ? process.env.REACT_APP_FOOTER_CONTACTUS
                  : "/"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact Us
            </a>
          </span>
        )}
        {process.env.REACT_APP_FOOTER_TERMS && (
          <span>
            {" "}
            |{" "}
            <a
              href={
                process.env.REACT_APP_FOOTER_TERMS
                  ? process.env.REACT_APP_FOOTER_TERMS
                  : "/"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms
            </a>
          </span>
        )}
        {process.env.REACT_APP_FOOTER_PRIVACY && (
          <span>
            {" "}
            |{" "}
            <a
              href={
                process.env.REACT_APP_FOOTER_PRIVACY
                  ? process.env.REACT_APP_FOOTER_PRIVACY
                  : "/"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy
            </a>
          </span>
        )}{" "}
        © {process.env.REACT_APP_TENANT_TITLE} {new Date().getFullYear()}
      </span>
    );
  }
}
