import { combineReducers } from 'redux'
import appDefault from './defaultReducer'
import auth from './auth'
import user from './users'
import notification from './notification'
import recipients from './recipients'
import transactions from './transactions'
import products from './products'
import channels from './channels'
import types from './types'
import sendMoney from './sendMoney'
import countries from './countries'
import payinStatus from './payinStatus'
import status from './status'
import banks from './banks'
import reactmodal from './reactmodal'

export default combineReducers({
  appDefault,
  auth,
  user,
  notification,
  recipients,
  transactions,
  products,
  channels,
  types,
  sendMoney,
  countries,
  payinStatus,
  status,
  banks,
  reactmodal
})
