const initState = {
  error: false,
  errorMessage: "",
  loading: false,
  ratesLoading: false,
  recipientLoading: false,
  quoteLoading: false,
  otpLoading: false,
  quoted: false,
  paymentInfoSet: false,
  confirmedQuotation: false,
  confirmedPayment: false,
  loadingText: "Loading...",
  isSendAgain: false,
  recipient: {},
  quotationResult: {},
  confirmationResult: {},
  paymentOptionId: null,
  paymentMethodId: null,
  paymentInfo: {},
  confirmedRemittance: false,
  setTabKey: null,
  otp: {
    errorMessage: "",
    otp_received: false,
    otp_has_error: false,
    otp_success: false,
    otp_payload: null,
    otp_form_data: { remittance: null, paymentMethodId: null, ccObj: null },
  },
  rates: null,
  complianceErrorMsg: "",
  boolComplianceError: false
};

export default function sendMoney(state = initState, action) {
  switch (action.type) {
    case "LIST_RATES":
      return {
        ...state,
        loading: false,
        ratesLoading: false,
        rates: action.payload,
      };

    case "SEND_MONEY_RECIPIENT":
      return {
        ...state,
        loading: false,
        recipientLoading: false,
        recipient: {
          id: action.payload.id,
          firstname: action.payload.firstname,
          middlename: action.payload.middlename,
          lastname: action.payload.lastname,
          companyname: action.payload.companyName,
          mobileNumber: action.payload.mobileNumber,
          street: action.payload.street,
          address1: action.payload.address1,
          address2: action.payload.address2,
          city: action.payload.city,
          state: action.payload.state,
          country: action.payload.country,
          zipCode: action.payload.zipCode,
          bankName: action.payload.bankName,
          accountNumber: action.payload.accountNumber,
          bankIban: action.payload.bankIban,
          bankBic: action.payload.bankBic,
          bankBvn: action.payload.bankBvn,
          type: action.payload.type,
          statusCode: action.payload.statusCode,
          statusDescription: action.payload.statusDescription,
          channel: action.payload.channel.id,
        },
      };

    case "OTP_LOADING":
      return {
        ...state,
        otpLoading: true

      }

    case "OTP_RECEIVED":
      return {
        ...state,
        loading: false,
        otpLoading: false,
        otp: {
          errorMessage: "",
          otp_received: true,
          otp_has_error: false,
          otp_success: false,
          otp_payload: action.payload,
          otp_form_data: action.formData,
        },
      };

    case "INVALID_OTP":
      return {
        ...state,
        loading: false,
        otpLoading: false,
        otp: {
          errorMessage: "Invalid OTP entered!",
          otp_received: true,
          otp_has_error: true,
          otp_success: false,
          otp_payload: action.payload,
          otp_form_data: action.formData,
        },
      };

    case "OTP_SUCCESS":
      return {
        ...state,
        otp: {
          errorMessage: "",
          otp_received: true,
          otp_has_error: false,
          otp_success: true,
          otp_payload: state.otp.otp_payload,
          otp_form_data: state.otp.otp_form_data,
        },
        loading: false,
        otpLoading: false,
      };

    case "REQUEST_QUOTATION":
      return {
        ...state,
        loading: false,
        isSendAgain: action.isSendAgain,
        quoteLoading: false,
        quoted: true,
        quotationResult: action.payload,
      };

    case "CONFIRM_QUOTATION":
      return {
        ...state,
        loading: false,
        quoteLoading: false,
        confirmedQuotation: true,
      };

    case "REQUEST_CONFIRM_QUOTATION":
      return {
        ...state,
        loading: false,
        quoteLoading: false,
        quoted: true,
        quotationResult: action.payload,
        confirmedQuotation: true,
      };

    case "RESET_QUOTATION":
      return {
        ...state,
        loading: false,
        quoteLoading: false,
        quoted: false,
      };

    case "SET_PAYMENT_METHOD":
      return {
        ...state,
        paymentInfo: {},
        paymentInfoSet: false,
        paymentOptionId: action.optionId,
        paymentMethodId: action.methodId,
      };

    case "SET_PAYMENT_INFO":
      return {
        ...state,
        paymentInfo: action.payload,
        paymentInfoSet: action.setPaymentInfo,
      };

    case "UNSET_PAYMENT_INFO":
        return {
          ...state,
          paymentInfo: {},
          paymentInfoSet: false,
        };

    case "REMITTANCE_SUCCESS_CONFIRMATION":
      return {
        ...state,
        otp: { ...initState.otp },
        loading: false,
        confirmedRemittance: true,
      };

    case "SEND_MONEY_LOADING":
      return {
        ...state,
        loading: true,
      };

    case "SEND_MONEY_RECIPIENT_LOADING":
      return {
        ...state,
        recipientLoading: true,
      };

    case "SEND_MONEY_RATES_LOADING":
      return {
        ...state,
        ratesLoading: true,
      };

    case "SEND_MONEY_QUOTE_LOADING":
      return {
        ...state,
        quoteLoading: true,
      };

    case "SEND_MONEY_OTP_LOADING":
      return {
        ...state,
        ratesLoading: true,
      };

    case "SEND_MONEY_HIDE_LOADING":
      return {
        ...state,
        loading: false,
      };

    case "SEND_MONEY_ERROR":
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case "SEND_MONEY_RECIPIENT_ERROR":
      return {
        ...state,
        recipientLoading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case "SEND_MONEY_RATES_ERROR":
      return {
        ...state,
        ratesLoading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case "SEND_MONEY_QUOTE_ERROR":
      return {
        ...state,
        quoteLoading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case "SEND_MONEY_OTP_ERROR":
      return {
        ...state,
        otpLoading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case "SET_LOADING_TEXT":
      return {
        ...state,
        loadingText: action.text,
      };

    case "RESET_SEND_MONEY":
      return {
        ...initState,
      };

    case "SEND_MONEY_COMPLIANCE_ERROR":
      {
        return {
          ...state,
          complianceErrorMsg: action.errorMessage,
          boolComplianceError: action.boolHasError
        }
      }

    case "SET_TAB_KEY":
      return {
        ...state,
        setTabKey: action.payload,
      };

    case "LOGOUT":
      return initState;

    default:
      return state;
  }
}
