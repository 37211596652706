import React from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CurrencyFlag from "react-currency-flags";
import getSymbolFromCurrency from "currency-symbol-map";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import Aux from "../../../hoc/_Aux";
import LoaderButton from "../../../App/components/LoaderButton";
import FooterSpan from "../../../App/components/FooterSpan";
import {
  fetchRates,
  //  sendMoneyLoading 
} from "../../../store/actions/sendMoney";
import LoadingOverlay from "react-loading-overlay";

class Rates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      youSend: 1,
      recipientGets: 1,
      dropdownOpen: false,
      dropdownOpen2: false,
      sendingCurrency: "GBP",
      sendingCurrencySymbol: "£",
      receivingCurrency: "GBP",
      receivingCurrencySymbol: "£",
      receivingFxRate: 1,
    };
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleBackToLogin = (event) => {
    this.props.history.push("/login");
  };

  toggleSend = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  toggleGets = () => {
    this.setState((prevState) => ({
      dropdownOpen2: !prevState.dropdownOpen2,
    }));
  };

  updateSendingCurrency = (currency) => {
    this.setState({
      sendingCurrency: currency,
      sendingCurrencySymbol: getSymbolFromCurrency(currency),
    });
    this.props.fetchRates(currency);
  };

  updateReceivingCurrency = (currency, fxRate) => {
    this.setState({
      receivingCurrency: currency,
      receivingCurrencySymbol: getSymbolFromCurrency(currency),
      receivingFxRate: fxRate,
      recipientGets: this.state.youSend * fxRate,
    });
  };

  componentDidMount() {
    this.props.fetchRates(this.state.sendingCurrency);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.sendMoney.rates &&
      this.props.sendMoney.rates.quotes &&
      this.props.sendMoney.rates.quotes.length > 0 &&
      (!prevState.receivingCurrency ||
        ((!prevProps.sendMoney.rates ||
          prevProps.sendMoney.rates.requestCurrency !==
          this.props.sendMoney.rates.requestCurrency) &&
          !this.props.sendMoney.rates.quotes.find(
            (q) => q.receivingCurrency === this.state.receivingCurrency
          )))
    ) {
      this.updateReceivingCurrency(
        this.props.sendMoney.rates.quotes[0].receivingCurrency,
        this.props.sendMoney.rates.quotes[0].fxRate
      );
    } else if (
      this.props.sendMoney.rates &&
      this.props.sendMoney.rates.quotes &&
      this.props.sendMoney.rates.quotes.length > 0 &&
      (!prevProps.sendMoney.rates ||
        prevProps.sendMoney.rates.requestCurrency !==
        this.props.sendMoney.rates.requestCurrency) &&
      this.props.sendMoney.rates.quotes.find(
        (q) => q.receivingCurrency === this.state.receivingCurrency
      )
    ) {
      this.updateReceivingCurrency(
        this.state.receivingCurrency,
        this.props.sendMoney.rates.quotes.find(
          (q) => q.receivingCurrency === this.state.receivingCurrency
        ).fxRate
      );
    } else if (prevState.youSend !== this.state.youSend) {
      this.setState({
        recipientGets: this.state.youSend * this.state.receivingFxRate,
      });
    } else if (prevState.recipientGets !== this.state.recipientGets) {
      this.setState({
        youSend: this.state.recipientGets / this.state.receivingFxRate,
      });
    }
  }

  render() {
    return (
      <Aux>
        <div className="d-flex auth-wrapper aut-bg-img flex-column justify-content-between">
          <div style={{ margin: "30px" }}>
            <div>
              <img
                src={
                  "/assets/tenants/" +
                  process.env.REACT_APP_TENANT.toLowerCase() +
                  "/logo.png"
                }
                alt=""
                className="headerLogo"
              />
            </div>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div className="auth-content">
              <div className="card">
                <div className="card-header d-flex flex-column justify-content-center align-items-center">
                  <h3 className="mb-3">
                    {process.env.REACT_APP_RATES_ACTION_TEXT}
                  </h3>
                  <span className="text-italic">
                    {process.env.REACT_APP_CATCHPHRASE_2}
                  </span>
                </div>
                <div className="card-body">
                  <LoadingOverlay
                    active={this.props.sendMoney.ratesLoading}
                    spinner
                    text="Fetching the best rates..."
                  >
                    <div>
                      <div className="form-group">
                        <label htmlFor="youSend">You Send</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            {" "}
                            <span className="input-group-text">
                              {this.state.sendingCurrencySymbol}
                            </span>{" "}
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            data-bv-field="youSend"
                            id="youSend"
                            name="youSend"
                            value={this.state.youSend}
                            onChange={this.handleChange}
                          />
                          <div className="input-group-append">
                            <span className="input-group-text p-0">
                              <Dropdown
                                isOpen={this.state.dropdownOpen}
                                toggle={this.toggleSend}
                              >
                                <DropdownToggle
                                  caret
                                  className="currencySelection"
                                >
                                  <CurrencyFlag
                                    currency={this.state.sendingCurrency}
                                  />{" "}
                                  {this.state.sendingCurrency}
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem
                                    onClick={this.updateSendingCurrency.bind(
                                      this,
                                      "GBP"
                                    )}
                                  >
                                    <CurrencyFlag currency="GBP" /> GBP
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={this.updateSendingCurrency.bind(
                                      this,
                                      "USD"
                                    )}
                                  >
                                    <CurrencyFlag currency="USD" /> USD
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="recipientGets">Recipient Gets</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            {" "}
                            <span className="input-group-text">
                              {this.state.receivingCurrencySymbol}
                            </span>{" "}
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            data-bv-field="recipientGets"
                            name="recipientGets"
                            id="recipientGets"
                            value={this.state.recipientGets}
                            onChange={this.handleChange}
                          />
                          <div className="input-group-append">
                            <span className="input-group-text p-0">
                              <Dropdown
                                isOpen={this.state.dropdownOpen2}
                                toggle={this.toggleGets}
                              >
                                <DropdownToggle
                                  caret
                                  className="currencySelection"
                                >
                                  <CurrencyFlag
                                    currency={this.state.receivingCurrency}
                                  />{" "}
                                  {this.state.receivingCurrency}
                                </DropdownToggle>
                                <DropdownMenu>
                                  {this.props.sendMoney.rates &&
                                    this.props.sendMoney.rates.quotes.map(
                                      (r, index) => {
                                        return (
                                          <DropdownItem
                                            key={index}
                                            onClick={this.updateReceivingCurrency.bind(
                                              this,
                                              r.receivingCurrency,
                                              r.fxRate
                                            )}
                                          >
                                            <CurrencyFlag
                                              currency={r.receivingCurrency}
                                            />{" "}
                                            {r.receivingCurrency}
                                          </DropdownItem>
                                        );
                                      }
                                    )}
                                </DropdownMenu>
                              </Dropdown>
                            </span>
                          </div>
                        </div>
                      </div>
                      {this.state.receivingCurrency && (
                        <p className="text-muted mb-0">
                          The current exchange rate is{" "}
                          <span className="font-weight-500">
                            1 {this.state.sendingCurrency} ={" "}
                            {this.state.receivingFxRate}{" "}
                            {this.state.receivingCurrency}
                          </span>
                        </p>
                      )}
                    </div>
                  </LoadingOverlay>
                </div>
                <div className="card-footer d-flex flex-row justify-content-center align-items-center">
                  <div className="d-flex flex-column">
                    <h5>{process.env.REACT_APP_ASK_REGISTER_CATCHPHRASE}</h5>
                    <span className="">
                      {process.env.REACT_APP_ASK_REGISTER_SUB_CATCHPHRASE}
                    </span>
                  </div>
                  <div style={{ minWidth: "100px" }}>
                    <LoaderButton
                      block
                      onClick={this.handleBackToLogin}
                      text="Login"
                      className="px-4 btn-secondary ml-3"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="login-footer">
              <span className="text-white">
                {process.env.REACT_APP_MAIN_CATCHPHRASE}
              </span>
            </div>
          </div>
          <div style={{ margin: "30px 0px" }}>
            <div className="footer-contact-info">
              <ul>
                {process.env.REACT_APP_SIGNIN_PAGE_ADDRESS && (
                  <li>
                    <FontAwesomeIcon icon={["fas", "map-marker-alt"]} />{" "}
                    {process.env.REACT_APP_SIGNIN_PAGE_ADDRESS}
                  </li>
                )}
                {process.env.REACT_APP_SIGNIN_PAGE_EMAIL && (
                  <li>
                    <FontAwesomeIcon icon={["fas", "envelope"]} /> Email:{" "}
                    <a
                      href={`mailto:${process.env.REACT_APP_SIGNIN_PAGE_EMAIL}`}
                    >
                      {process.env.REACT_APP_SIGNIN_PAGE_EMAIL}
                    </a>
                  </li>
                )}
                {process.env.REACT_APP_SIGNIN_PAGE_COMPLIANCE_EMAIL && (
                  <li>
                    <FontAwesomeIcon icon={["fas", "envelope"]} /> Compliance
                    Email:{" "}
                    <a
                      href={`mailto:${process.env.REACT_APP_SIGNIN_PAGE_COMPLIANCE_EMAIL}`}
                    >
                      {process.env.REACT_APP_SIGNIN_PAGE_COMPLIANCE_EMAIL}
                    </a>
                  </li>
                )}
                {process.env.REACT_APP_SIGNIN_PAGE_PHONE && (
                  <li>
                    <FontAwesomeIcon icon={["fas", "phone-volume"]} /> Phone:{" "}
                    <a
                      href={`tel:${process.env.REACT_APP_SIGNIN_PAGE_PHONE.replaceAll(
                        /\s/g,
                        ""
                      ).trim()}`}
                    >
                      {process.env.REACT_APP_SIGNIN_PAGE_PHONE}
                    </a>
                  </li>
                )}
              </ul>
            </div>
            <div className="footer-company-info d-flex justify-content-center">
              <FooterSpan />
            </div>
          </div>
        </div>
      </Aux>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    sendMoney: state.sendMoney,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchRates: (currency) => {
      dispatch({ type: "SEND_MONEY_RATES_LOADING" });
      dispatch(fetchRates(currency));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Rates);
