
//need for first render
const initState = {
    accessToken: false,
    error: false,
    reset_success: false,
    user: {
        fullname: null,
        account: {
            accountId: null,
            created: null,
            expirationDate: null,
            groups: null,
            id: null,
            roles: null,
            status: null,
            type: null,
            username: null,
            firstname: null,
            middlename: null,
            lastname: null
        },
        photo: {
            id: null,
            type: null,
            url: null
        },
        document: [],
        phonenumber: [],
        email: [],
    },
    loading: false,
    loading_image: false
}

export default function auth(state = initState, action) {

    switch (action.type) {

        case 'LOGIN':
            return {
                ...state,
                error: false,
                ...action.auth,
                loading: false,
                user: {
                  fullname: action.user.Fullname,
                  firstname: action.user.Firstname,
                  middlename: action.user.Middlename || "",
                  lastname: action.user.Lastname,
                  gender: action.user.Gender,
                  birthDate: action.user.BirthDate,
                  nationality:action.user.Nationality,
                  id: action.user.id,
                  account: { ...action.user.account },
                  photo: { ...action.user.photo },
                  document: action.user.document,
                  phonenumber: action.user.phonenumber.value,
                  email: action.user.email.value,
                  addressId: action.user.address.id, 
                  address1: action.user.address.address1,
                  address2: action.user.address.address2,
                  city: action.user.address.city,
                  country: action.user.address.country,
                  zipCode: action.user.address.zipCode,
                  state: action.user.address.state,
                },
            };

        case 'AUTH_LOADING':
            return {
                ...state,
                error: false,
                loading: true
            };

        case 'HIDE_LOADING':
            return {
                ...state,
                error: false,
                loading: false
            };

        case 'AUTH_FAILED':
            return {
                ...state,
                error: true,
                errorMsg: action.errorMsg,
                loading: false
            };

        case 'FETCH_CURR_USER':
            return {
                ...state,
                error: false,
                loading: false,
                user: {
                    fullname: action.user.Fullname,
                    firstname: action.user.Firstname,
                    middlename: action.user.Middlename || "",
                    lastname: action.user.Lastname,
                    gender: action.user.Gender,
                    birthDate: action.user.BirthDate,
                    nationality:action.user.Nationality,
                    id: action.user.id,
                    account: { ...action.user.account },
                    photo: { ...action.user.photo },
                    document: action.user.document,
                    phonenumber: action.user.phonenumber.value,
                    email: action.user.email.value,
                    address1: action.user.address.address1,
                    address2: action.user.address.address2,
                    city: action.user.address.city,
                    country: action.user.address.country,
                    zipCode: action.user.address.zipCode,
                    state: action.user.address.state,
                },
                loading_image: false
            };

        case 'DISMISS_AUTH_ERROR':
            return initState

        case 'LOGOUT':
            //Simply clear the auth data and return back to initial data
            sessionStorage.clear();
            window.location.href = '/';

            return initState

        case 'FORGET_PASSWORD':
            return {
                ...state,
                error: false,
                loading: false,
                reset_success: true //don't show anything but show the user
            };

        case 'RESET_PASSWORD':
            return {
                ...state,
                error: false,
                loading: false,
                reset_success: true //don't show anything but show the user
            };

        case 'LOADING_CURRUSER_IMAGE':
            return {
                ...state,
                loading_image: true
            };

        case 'CURR_USER_IMAGE_UPDATED':
            return {
                ...state,
                user: {
                    ...state.user,
                    photo: {
                        ...state.user.photo,
                        url: action.photoUrl
                    }
                },
                error: false,
                errorMsg: '',
                loading_image: false
            }

        default:
            return state
    }
}
