import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Aux from "../../../hoc/_Aux";
import LoaderButton from "../../../App/components/LoaderButton";

import { connect } from "react-redux";
import {
  postLogin,
  dismissAuthError,
  setLoading,
  fetchCurrUser,
} from "../../../store/actions/auth";

import { Alert, FormGroup, Label, Input } from "reactstrap";
import FooterSpan from "../../../App/components/FooterSpan";
import { dismissError } from "../../../store/actions/users";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import startsWith from "lodash.startswith";

import classNames from "classnames";

class SignUp1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      phonenumber: "",
      email: "",
      dialCode: "",
      countryCode:
        process.env.REACT_APP_DEFAULT_COUNTRY_ISO2_CODE.toLowerCase(),
      password: "",
      loginType: "phone",
      boolPhoneLenError: false,
      boolPhoneInvalid: true,
      hasRendered: false,
    };

    this.onLoginTypeToggle = this.onLoginTypeToggle.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.loginForm = React.createRef();
  }

  validateForm = () => {
    if (this.state.loginType === "phone") {
      return (
        !this.state.boolPhoneLenError &&
        !this.state.boolPhoneInvalid &&
        this.state.phonenumber.length > 0
      );
    } else if (this.state.loginType === "email") {
      return this.state.password.length > 0;
    } else {
      return true;
    }
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value.trim(),
    });
  };

  handleForget = (event) => {
    event.preventDefault();

    this.dismissAllErrors();
    this.props.history.push("/forgetpassword");
  };

  handleRates = (event) => {
    event.preventDefault();

    this.dismissAllErrors();
    this.props.history.push("/rates");
  };

  handleRegisterUser = (event) => {
    event.preventDefault();

    this.dismissAllErrors();
    this.props.history.push("/register");
  };

  dismissAllErrors = () => {
    this.props.dismissAuthError();
    this.props.dismissUserError();
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    if (
      this.state.loginType === "phone" &&
      this.state.dialCode.length >= this.state.phonenumber.length
    ) {
      this.setState({ boolPhoneLenError: true });
      return;
    }

    this.dismissAllErrors();

    var payload = {
      username:
        this.state.loginType === "phone"
          ? this.state.phonenumber
          : this.state.loginType === "email"
          ? this.state.email
          : "",
      password: this.state.password,
    };

    this.props.showLoading();
    this.props.login(payload);
  };

  componentDidUpdate(prevProps) {
    if (this.props.auth.accessToken) {
      this.props.userHasAuthenticated(
        true,
        this.props.auth.user.account.status !== 8
      );
    }
  }

  componentDidMount() {
    this.setState({ hasRendered: true });
  }

  onLoginTypeToggle = (e, value) => {
    this.setState({
      loginType: e.currentTarget.value,
    });

    if (e.currentTarget.value === "email") {
      this.setState({ boolPhoneLenError: false });
    }

    this.loginForm.reset();
  };

  setPhone = (phoneData) => {
    this.setState({
      phonenumber: "+" + phoneData.phone,
      dialCode: phoneData.dialCode,
      boolPhoneLenError: false,
    });
  };

  onBlurPhoneInput = (e, country) => {
    let inputNumber = e.target.value.substring(1);
    let { dialCode } = country;

    if (dialCode.length === inputNumber.length) {
      this.setState({ boolPhoneLenError: true });
    }
  };

  handlePhoneValidity = (inputNumber, country) => {
    let BoolIsValid = this.checkValidity(inputNumber, country);

    return BoolIsValid;
  };

  checkValidity = (inputNumber, country) => {
    let BoolIsValid = false;

    let { dialCode, format } = country;

    BoolIsValid =
      startsWith(inputNumber, dialCode) || startsWith(dialCode, inputNumber);

    let intFormatLength = 0;
    for (var i = 0; i < format.length; i++) {
      if (format[i] === ".") {
        intFormatLength++;
      }
    }

    if (
      inputNumber.length > dialCode.length &&
      inputNumber.length < intFormatLength / 2
    ) {
      BoolIsValid = false;
    } else {
      BoolIsValid = true;
    }

    return BoolIsValid;
  };

  handleSetPhone = (phone, country) => {
    let BoolIsValid = this.checkValidity(phone, country);

    if (BoolIsValid === this.state.boolPhoneInvalid && this.state.hasRendered) {
      this.setState({
        boolPhoneInvalid: !BoolIsValid,
      });
    }

    let { dialCode } = country;
    this.setPhone({ phone, dialCode });
  };

  render() {
    let phoneInputProps = {
      name: "phoneInput",
      id: "phoneInput",
      required: true,
      autoFocus: true,
    };

    let phoneInputClass = classNames("ml-5", {
      "invalid-number": this.state.boolPhoneLenError,
    });

    return (
      <Aux>
        <div className="d-flex auth-wrapper aut-bg-img flex-column justify-content-between">
          <div style={{ margin: "30px" }}>
            <div>
              <img
                src={
                  "/assets/tenants/" +
                  process.env.REACT_APP_TENANT.toLowerCase() +
                  "/logo.png"
                }
                alt=""
                className="headerLogo"
              />
            </div>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div className="auth-content">
              <div className="card">
                <div className="card-header d-flex flex-column justify-content-center align-items-center">
                  <h3 className="mb-3">
                    {process.env.REACT_APP_LOGIN_ACTION_TEXT}
                  </h3>
                  <span className="text-italic">
                    {process.env.REACT_APP_CATCHPHRASE_2}
                  </span>
                </div>
                <div className="card-body">
                  <Alert
                    color="danger"
                    isOpen={this.props.auth.error || this.props.user.error}
                    toggle={this.dismissAllErrors}
                  >
                    {(this.props.user.error && this.props.user.errorMsg) ||
                      (this.props.auth.error && this.props.auth.errorMsg)}
                  </Alert>
                  <div className="mb-3">
                    <form
                      ref={(f) => (this.loginForm = f)}
                      onSubmit={this.handleSubmit}
                    >
                      <div className="mb-3 text-center">
                        <FormGroup check className="d-inline">
                          <Label check>
                            <Input
                              type="radio"
                              name="loginTypeRadio"
                              value="phone"
                              checked={this.state.loginType === "phone"}
                              onChange={this.onLoginTypeToggle}
                            />
                            &nbsp;Phone
                          </Label>
                        </FormGroup>
                        <FormGroup check className="d-inline ml-5">
                          <Label check>
                            <Input
                              type="radio"
                              name="loginTypeRadio"
                              value="email"
                              checked={this.state.loginType === "email"}
                              onChange={this.onLoginTypeToggle}
                            />
                            &nbsp;Email
                          </Label>
                        </FormGroup>
                      </div>
                      {this.state.loginType === "phone" && (
                        <React.Fragment>
                          <PhoneInput
                            country={this.state.countryCode}
                            onChange={this.handleSetPhone}
                            containerClass="input-group"
                            inputClass={phoneInputClass}
                            inputProps={phoneInputProps}
                            prefix="+"
                            countryCodeEditable={false}
                            defaultErrorMessage="Invalid Phone Number"
                            isValid={this.handlePhoneValidity}
                            required
                          />

                          {this.state.boolPhoneLenError && (
                            <label className="text-danger">
                              Required Mobile Number
                            </label>
                          )}
                          <input
                            type="hidden"
                            id="phonenumber"
                            className="form-control"
                            value={this.state.phonenumber}
                            required
                            minLength="4"
                          />
                        </React.Fragment>
                      )}
                      {this.state.loginType === "email" && (
                        <div className="input-group">
                          <input
                            type="email"
                            id="email"
                            className="form-control"
                            placeholder="Email"
                            value={this.state.email}
                            onChange={this.handleChange}
                            required
                          />
                        </div>
                      )}
                      <div className="input-group mt-3 mb-4">
                        <input
                          type="password"
                          id="password"
                          className="form-control"
                          placeholder="Password"
                          value={this.state.password}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                      <LoaderButton
                        block
                        disabled={
                          this.props.auth.loading ||
                          this.props.user.loading ||
                          !this.validateForm()
                        }
                        type="submit"
                        isLoading={
                          this.props.auth.loading || this.props.user.loading
                        }
                        text="Login"
                        loadingText="Logging you in..."
                        className="px-4 btn-login"
                      />
                    </form>
                  </div>
                  <a href="/forgetpassword">Forgot password?</a>
                </div>
                <div className="card-footer d-flex flex-column justify-content-center align-items-center">
                  <div className="d-flex flex-row justify-content-center align-items-center mb-3">
                    <div className="d-flex flex-column">
                      <h5>{process.env.REACT_APP_ASK_REGISTER_CATCHPHRASE}</h5>
                      <span className="">
                        {process.env.REACT_APP_ASK_REGISTER_SUB_CATCHPHRASE}
                      </span>
                    </div>
                    <div style={{ minWidth: "100px" }}>
                      <LoaderButton
                        block
                        type="button"
                        disabled={this.props.auth.loading}
                        onClick={this.handleRegisterUser}
                        text="Sign up"
                        className="px-4 btn-secondary ml-3"
                      />
                    </div>
                  </div>
                  <LoaderButton
                    block
                    disabled={
                      this.props.auth.loading || this.props.user.loading
                    }
                    type="button"
                    onClick={this.handleRates}
                    text="Want to check rates?"
                    loadingText="Loading..."
                    className="px-4 btn-alternate"
                  />
                </div>
              </div>
            </div>
            <div className="login-footer">
              <span className="text-white">
                {process.env.REACT_APP_MAIN_CATCHPHRASE}
              </span>
            </div>
          </div>
          <div style={{ margin: "30px 0px" }}>
            <div className="footer-contact-info">
              <ul>
                {process.env.REACT_APP_SIGNIN_PAGE_ADDRESS && (
                  <li>
                    <FontAwesomeIcon icon={["fas", "map-marker-alt"]} />{" "}
                    {process.env.REACT_APP_SIGNIN_PAGE_ADDRESS}
                  </li>
                )}
                {process.env.REACT_APP_SIGNIN_PAGE_EMAIL && (
                  <li>
                    <FontAwesomeIcon icon={["fas", "envelope"]} /> Email:{" "}
                    <a
                      href={`mailto:${process.env.REACT_APP_SIGNIN_PAGE_EMAIL}`}
                    >
                      {process.env.REACT_APP_SIGNIN_PAGE_EMAIL}
                    </a>
                  </li>
                )}
                {process.env.REACT_APP_SIGNIN_PAGE_COMPLIANCE_EMAIL && (
                  <li>
                    <FontAwesomeIcon icon={["fas", "envelope"]} /> Compliance
                    Email:{" "}
                    <a
                      href={`mailto:${process.env.REACT_APP_SIGNIN_PAGE_COMPLIANCE_EMAIL}`}
                    >
                      {process.env.REACT_APP_SIGNIN_PAGE_COMPLIANCE_EMAIL}
                    </a>
                  </li>
                )}
                {process.env.REACT_APP_SIGNIN_PAGE_PHONE && (
                  <li>
                    <FontAwesomeIcon icon={["fas", "phone-volume"]} /> Phone:{" "}
                    <a
                      href={`tel:${process.env.REACT_APP_SIGNIN_PAGE_PHONE.replaceAll(
                        /\s/g,
                        ""
                      ).trim()}`}
                    >
                      {process.env.REACT_APP_SIGNIN_PAGE_PHONE}
                    </a>
                  </li>
                )}
              </ul>
            </div>
            <div className="footer-company-info d-flex justify-content-center">
              <FooterSpan />
            </div>
          </div>
        </div>
      </Aux>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (objCredentials) => {
      dispatch(postLogin(objCredentials));
    },
    dismissAuthError: () => {
      dispatch(dismissAuthError());
    },
    dismissUserError: () => {
      dispatch(dismissError());
    },
    showLoading: () => {
      dispatch(setLoading());
    },
    fetchCurrUser: (userId) => {
      dispatch(fetchCurrUser(userId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp1);
