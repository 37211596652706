import Config from "./../../config";
import moment from "moment";

const initState = {
  data: [],
  selected: {},
  loading: false,
  loadingText: "Loading...",
  userTransactionData: [],
  meta: Config.defaultMeta,
  reportFileName: "Transaction List.xlsx",
  reportHeaders: [],
  reportData: [],
  reportStartDate: null,
  reportendDate: null,
  reportGenerated: false,
  error: false,
  errorMsg: "",
};

export default function transactions(state = initState, action) {
  switch (action.type) {
    case "FETCH_TRANSACTIONS_LIST":
      return {
        ...state,
        error: false,
        data: action.payload,
        meta: action.meta,
        loading: false,
        loading_image: false,
        reportGenerated: true,
      };

    case "FETCH_TRANSACTION_DETAILS":
      moment().utcOffset(action.payload.transactionDate);
      let tDate = moment(action.payload.transactionDate).format(
        process.env.REACT_APP_DATE_FORMAT + " " + process.env.REACT_APP_TIME_FORMAT
      );

      return {
        ...state,
        selected: {
          referenceNumber: action.payload.referenceNumber,
          transactionDate: tDate,
          senderName: action.payload.senderName,
          senderCurrency: action.payload.senderCurrency,
          senderAmount: action.payload.senderAmount,
          receiverName: action.payload.receiverName,
          receiverCurrency: action.payload.receiverCurrency,
          receiverAmount: action.payload.receiverAmount,
          transactionFee: action.payload.transactionFee,
          transactionFx: action.payload.transactionFx,
          statusId: action.payload.statusId,
          status: action.payload.status,
          payoutChannel: action.payload.payoutChannel,
          accountNumber: action.payload.accountNumber,
          purpose: action.payload.purpose || "",
          senderRemarks: action.payload.senderRemarks || "",
          adminNotes: action.payload.adminNotes || "",
        },
      };

    case "FETCH_USER_TRANSACTIONS_LIST":
      return {
        ...state,
        error: false,
        userTransactionData: action.payload,
        // meta: action.meta,
        userTransLoading: false,
        reportGenerated: true,
      };

    case "FETCH_TRANSACTIONS_REPORT":
      let headers = [
        { label: "Type", key: "type" },
        { label: "Unique Id", key: "id" },
        { label: "Reference Number", key: "referenceNumber" },
        { label: "Sender Fullname", key: "senderFullname" },
        { label: "Sender RoleName", key: "senderRoleName" },
        { label: "Sender GroupName", key: "senderGroupName" },
        { label: "Receiver Fullname", key: "receiverFullname" },
        { label: "Receiver RoleName", key: "receiverRoleName" },
        { label: "Receiver GroupName", key: "receiverGroupName" },
        { label: "Amount", key: "amount" },
        { label: "Type Name", key: "typeName" },
        { label: "Remarks", key: "remarks" },
        { label: "External Id", key: "externalId" },
        { label: "Created", key: "created" },
      ];

      return {
        ...state,
        reportData: action.payload,
        reportHeaders: headers,
        reportGenerated: true,
        reportFileName:
          "Transaction_Report_" +
          action.startdate +
          "_" +
          action.enddate +
          ".csv",
        reportStartDate: action.startdate,
        reportendDate: action.enddate,
      };

    case "FETCH_TRANSACTIONS_REPORT_PDF":
      return {
        ...state,
        pdfData: action.payload,
        error: false,
        loading: false,
      };

    case "REPORT_LOADING":
      return {
        ...state,
        error: false,
        reportGenerated: false,
      };

    case "TRANSACTION_LOADING":
      return {
        ...state,
        error: false,
        loading: true,
        loadingText: "Loading transaction..",
      };

    case "HIDE_LOADING":
      return {
        ...state,
        error: false,
        loading: false,
      };

    case "USER_TRANS_LOADING":
      return {
        ...state,
        error: false,
        userTransLoading: true,
      };

    case "USER_TRANS_LOADING_HIDE":
      return {
        ...state,
        error: false,
        userTransLoading: false,
      };

    case "ERROR_TRANSACTIONS":
      return {
        ...state,
        error: true,
        errorMsg: action.errorMsg,
        loading: false,
        loading_image: false,
      };

    case "DISMISS_USER_ERROR":
      return {
        ...state,
        error: false,
        errorMsg: "",
        loading: false,
        loading_image: false,
      };

    case "RESET_TRANSACTIONS":
      return {
        ...initState,
      };

    case "LOGOUT":
      return initState;

    default:
      return state;
  }
}
