import axios from "axios";
import http from "../api/auth";
import Jsona from "jsona";

import Config from "../../config";
// import Constant from "../constant";

import { showNotification } from "./notification";

// const strGeneralError = Constant.GENERIC_ERROR_MSG;

export function postLogin(objCredentials) {
  let apiBaseUrl =
    process.env.REACT_APP_IDENTITY_BASE_URL +
    process.env.REACT_APP_IDENTITY_LOGIN_URI;

  let objRequest = {
    data: {
      type: "token",
      attributes: {
        ...objCredentials,
        clientid: process.env.REACT_APP_CLIENT_ID,
      },
    },
  };

  const request = axios.post(apiBaseUrl, objRequest);

  return (dispatch) => {
    return request.then(
      ({ data }) => {
        const dataFormatter = new Jsona();
        let res = dataFormatter.deserialize(data);

        dispatch(fetchCurrUser(res));
        dispatch(hideLoading());
      },
      (error) => {
        //simply check type of error message
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          dispatch({
            type: "AUTH_FAILED",
            errorMsg: error.response.data.errors.message
              ? error.response.data.errors.message
              : "Login failed!",
          });
        } else if (error.messages) {
          dispatch({
            type: "AUTH_FAILED",
            errorMsg: error.messages,
          });
        } else {
          dispatch({
            type: "AUTH_FAILED",
            errorMsg: "Login failed!",
          });
        }
      }
    );
  };
}

export function setLoading() {
  return (dispatch) => {
    dispatch({
      type: "AUTH_LOADING",
    });
  };
}

export function hideLoading() {
  return (dispatch) => {
    dispatch({
      type: "HIDE_LOADING",
    });
  };
}

export function postLogout() {
  return (dispatch) => {
    dispatch({
      type: "LOGOUT",
    });
  };
}

export function dismissAuthError() {
  return (dispatch) => {
    dispatch({
      type: "DISMISS_AUTH_ERROR",
    });
  };
}

export function postForgetPassword(objEmail) {
  let apiBaseUrl =
    process.env.REACT_APP_IDENTITY_BASE_URL +
    process.env.REACT_APP_IDENTITY_FORGET_URI;

  let objRequest = {
    data: {
      type: "Account",
      attributes: {
        ...objEmail,
        tenant: Config.REACT_APP_TENANT,
      },
    },
  };

  const request = axios.post(apiBaseUrl, objRequest);

  return (dispatch) => {
    request.then(
      ({ data }) => {
        dispatch({
          type: "FORGET_PASSWORD",
          payload: data,
        });

        dispatch(
          showNotification({
            type: "success",
            message: "We’ve sent you an email for futher instructions.",
          })
        );
      },
      (error) => {
        if (error.response) {
          dispatch({
            type: "AUTH_FAILED",
            errorMsg: error.response.data.errors.message
              ? error.response.data.errors.message
              : "Reset password failed. Please try again later.",
          });

          dispatch(
            showNotification({
              type: "error",
              message: error.response.data.errors.message
                ? error.response.data.errors.message
                : "Reset password failed. Please try again later.",
            })
          );
        } else if (error.messages) {
          dispatch({
            type: "AUTH_FAILED",
            errorMsg: error.messages,
          });
          dispatch(
            showNotification({
              type: "error",
              message: error.messages,
            })
          );
        } else {
          dispatch({
            type: "AUTH_FAILED",
            errorMsg: error.messages,
          });
          dispatch(
            showNotification({
              type: "error",
              message: error.messages,
            })
          );
        }
      }
    );
  };
}

export function patchResetPassword(objPasswordReset, history, newPassMode) {
  let apiBaseUrl =
    process.env.REACT_APP_IDENTITY_BASE_URL +
    process.env.REACT_APP_IDENTITY_RESET_URI;

  let payload = {
    data: {
      type: "Account",
      attributes: {
        ...objPasswordReset,
      },
    },
  };

  const request = axios.patch(apiBaseUrl, payload);

  return (dispatch) => {
    request.then(
      ({ data }) => {
        dispatch({
          type: "RESET_PASSWORD",
          payload: data,
        });
        dispatch(
          showNotification({
            type: "success",
            message: (newPassMode ? "Your account is now ready! " : "") + "Password has been set successfully.",
          })
        );
        if (history) {
          history.push("/");
        }
      },
      (error) => {
        if (error.response) {
          dispatch({
            type: "AUTH_FAILED",
            errorMsg: error.response.data.errors.message
              ? error.response.data.errors.message
              : "Setting password failed. Please try again later.",
          });

          dispatch(
            showNotification({
              type: "error",
              message: error.response.data.errors.message
                ? error.response.data.errors.message
                : "Setting password failed. Please try again later.",
            })
          );
        } else if (error.messages) {
          dispatch({
            type: "AUTH_FAILED",
            errorMsg: error.messages,
          });
          dispatch(
            showNotification({
              type: "error",
              message: error.messages,
            })
          );
        } else {
          dispatch({
            type: "AUTH_FAILED",
            errorMsg: "Setting password failed. Please try again later.",
          });
          dispatch(
            showNotification({
              type: "error",
              message: "Setting password failed. Please try again later.",
            })
          );
        }
      }
    );
  };
}

export function getAuthHeader(boolAuthHeaderOnly = false) {
  if (sessionStorage.getItem("usersession")) {
    let authSession = JSON.parse(sessionStorage.getItem("usersession"));
    if (!boolAuthHeaderOnly) {
      let request = {
        headers: {
          Authorization: "Bearer " + authSession.accessToken,
        },
      };

      return request;
    } else {
      let request = "Bearer " + authSession.accessToken;

      return request;
    }
  } else {
    return null;
  }
}

//simply add the fetch user profile to get the data
const defaultParams = {
  include: Config.REACT_APP_PROFILE_INCLUDE,
};

export function fetchCurrUser(objAccess) {
  //store the value first...
  sessionStorage.setItem("usersession", JSON.stringify(objAccess));

  let apiBaseUrl =
    process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_USER_URI;

  apiBaseUrl += objAccess.id;

  let params = {
    params: {
      ...defaultParams,
    },
  };

  const request = http.get(apiBaseUrl, params);

  const dataFormatter = new Jsona();

  return (dispatch) => {
    request.then(
      ({ data }) => {
        let user = dataFormatter.deserialize(data);

        if (user.account.status === 8 && (!user.document || user.document.length === 0)) {
          sessionStorage.clear();

          dispatch({
            type: "ERROR_USERS",
            errorMsg: `There was a problem in getting your user KYC document details. Please contact ${process.env.REACT_APP_TENANT_TITLE} Admin.`,
          });

          return;
        }

        //store the user info to get reloading correctly
        sessionStorage.setItem("userinfo", JSON.stringify(user));

        //dispatch
        dispatch({
          type: "LOGIN",
          user: user,
          auth: objAccess,
        });

        //add loader here...
      },
      (error) => {
        sessionStorage.clear();

        dispatch({
          type: "ERROR_USERS",
          errorMsg: `There was a problem in getting your user details. Please contact ${process.env.REACT_APP_TENANT_TITLE} Admin.`,
        });
      }
    );
  };
}

export function uploadUserProfilePic(userId, picture) {
  let apiBaseUrl =
    process.env.REACT_APP_IDENTITY_BASE_URL +
    process.env.REACT_APP_IMAGE_UPLOAD_URI;

  apiBaseUrl += userId;

  var formData = new FormData();

  formData.append("file", picture.pop());

  //build form header
  http.interceptors.request.use((config) => {
    config.headers = { "Content-Type": "multipart/form-data" };
    return config;
  });

  const request = http.post(apiBaseUrl, formData);

  return (dispatch) => {
    request.then(
      ({ data }) => {
        dispatch({
          type: "CURR_USER_IMAGE_UPDATED",
          photoUrl: data.data.attributes.url,
        });
      },
      (error) => {
        dispatch({
          type: "ERROR_USERS",
          errorMsg: error.messages,
        });
      }
    );
  };
}

export function setImageLoading() {
  return (dispatch) => {
    dispatch({
      type: "LOADING_CURRUSER_IMAGE",
    });
  };
}

export function fetchUpdatedUserProfile() {
  let objAuthSession = JSON.parse(sessionStorage.getItem("usersession"));
  return (dispatch) => {
    dispatch(fetchCurrUser(objAuthSession));
  };
}
