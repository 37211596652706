const initState = {
  defaultSelectedIso: "gm",
  defaultSelectedCode: "220",
  defaultSelectedNationality: "Gambian",
  defaultSelectedCountry: "Gambia",
  list: [],
};

export default function nationality(state = initState, action) {
  switch (action.type) {
    case "FETCH_COUNTRIES_DATA":
      return {
        ...state,
        list: action.list,
      };

    case "LOGOUT":
      return initState;

    default:
      return state;
  }
}
