import React from "react";
import { Route, Redirect } from "react-router-dom";

export default ({ component: C, props: cProps, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (cProps.isAuthenticated) {
          if (cProps.isPending && props.location.pathname !== "/user-pending") {
            return <Redirect to="/user-pending" />;
          } else if (
            !cProps.isPending &&
            props.location.pathname === "/user-pending"
          ) {
            return <Redirect to="/" />;
          } else {
            return <C {...props} {...cProps} />;
          }
        } else {
          return (
            <Redirect
              to={`/login?redirect=${props.location.pathname}${props.location.search}`}
            />
          );
        }
      }}
    />
  );
};
