import React from "react";
import { connect } from "react-redux";
import { Alert } from "reactstrap";
import isEmail from "validator/lib/isEmail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Aux from "../../../hoc/_Aux";
import LoaderButton from "../../../App/components/LoaderButton";
import FooterSpan from "../../../App/components/FooterSpan";

import {
  postForgetPassword,
  dismissAuthError,
  setLoading,
} from "../../../store/actions/auth";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };
  }

  validateForm() {
    return isEmail(this.state.email);
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleBackToLogin = (event) => {
    this.props.dismissError();
    this.props.history.push("/login");
  };

  handleSubmit = (event, values) => {
    event.preventDefault();

    this.props.showLoading();
    this.props.forgetPassword({ email: this.state.email });
  };

  render() {
    return (
      // <Aux>
      //   <Breadcrumb />
      //   <div className="auth-wrapper aut-bg-img-side cotainer-fiuid align-items-stretch">
      //     <div className="row align-items-center w-100 align-items-stretch bg-white">
      //       <div className="d-none d-lg-flex col-lg-8 aut-bg-img align-items-center d-flex justify-content-center">
      //         <div className="col-md-8">
      //           <h1 className="text-white mb-5">
      //             Your money’s in safe hands with us{" "}
      //           </h1>
      //           <p className="text-white">
      //             Faster, simpler, safer – send to those you love today.
      //           </p>
      //         </div>
      //       </div>
      //       <div className="col-lg-4 align-items-stret h-100 align-items-center d-flex justify-content-center">
      //         <form onSubmit={this.handleSubmit}>
      //           <div className=" auth-content text-center">
      //             <div className="mb-4">
      //               <i className="feather icon-mail auth-icon" />
      //             </div>
      //             <h3 className="mb-4">Reset Password</h3>
      //             <div className="input-group mb-3">
      //               <input
      //                 type="email"
      //                 required
      //                 id="email"
      //                 className="form-control"
      //                 placeholder="Email"
      //                 value={this.state.email}
      //                 onChange={this.handleChange}
      //               />
      //             </div>
      //             <LoaderButton
      //               block
      //               //  disabled={!this.validateForm()}
      //               type="submit"
      //               isLoading={this.props.auth.loading}
      //               text="Reset Password"
      //               loadingText="Loading..."
      //               className="px-4 btn btn-primary"
      //             />
      //             <p className="mb-0 text-muted">
      //               Login your account now!
      //               <button
      //                 className="ButtonLink"
      //                 onClick={this.handleBackToLogin}
      //               >
      //                 Signin
      //               </button>
      //             </p>
      //           </div>
      //         </form>
      //       </div>
      //     </div>
      //   </div>
      // </Aux>
      <Aux>
        <div className="d-flex auth-wrapper aut-bg-img flex-column justify-content-between">
          <div style={{ margin: "30px" }}>
            <div>
              <img
                src={
                  "/assets/tenants/" +
                  process.env.REACT_APP_TENANT.toLowerCase() +
                  "/logo.png"
                }
                alt=""
                className="headerLogo"
              />
            </div>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div className="auth-content">
              <div className="card">
                <div className="card-header d-flex flex-column justify-content-center align-items-center">
                  <h3 className="mb-3">Reset Password</h3>
                  <span className="text-italic">
                    Link to reset password will be sent to your email.
                  </span>
                </div>
                <div className="card-body">
                  <Alert
                    color="danger"
                    isOpen={this.props.auth.error}
                    toggle={this.props.dismissError}
                  >
                    {this.props.auth.errorMsg}
                  </Alert>
                  <div>
                    <form onSubmit={this.handleSubmit}>
                      <div className="input-group mb-3">
                        <input
                          type="email"
                          id="email"
                          className="form-control"
                          placeholder="Enter your email"
                          value={this.state.email}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                      <LoaderButton
                        block
                        disabled={
                          this.props.auth.loading || !this.validateForm()
                        }
                        type="submit"
                        isLoading={this.props.auth.loading}
                        text="Reset Password"
                        loadingText="Loading..."
                        className="px-4 btn-login"
                      />
                    </form>
                  </div>
                </div>
                <div className="card-footer d-flex flex-row justify-content-center align-items-center">
                  <div className="d-flex flex-column">
                    <h5>{process.env.REACT_APP_ASK_REGISTER_CATCHPHRASE}</h5>
                    <span className="">
                      {process.env.REACT_APP_ASK_REGISTER_SUB_CATCHPHRASE}
                    </span>
                  </div>
                  <div style={{ minWidth: "100px" }}>
                    <LoaderButton
                      block
                      disabled={this.props.auth.loading}
                      onClick={this.handleBackToLogin}
                      text="Login"
                      className="px-4 btn-secondary ml-3"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="login-footer">
              <span className="text-white">
                {process.env.REACT_APP_MAIN_CATCHPHRASE}
              </span>
            </div>
          </div>
          <div style={{ margin: "30px 0px" }}>
            <div className="footer-contact-info">
              <ul>
                {process.env.REACT_APP_SIGNIN_PAGE_ADDRESS && (
                  <li>
                    <FontAwesomeIcon icon={["fas", "map-marker-alt"]} />{" "}
                    {process.env.REACT_APP_SIGNIN_PAGE_ADDRESS}
                  </li>
                )}
                {process.env.REACT_APP_SIGNIN_PAGE_EMAIL && (
                  <li>
                    <FontAwesomeIcon icon={["fas", "envelope"]} /> Email:{" "}
                    <a
                      href={`mailto:${process.env.REACT_APP_SIGNIN_PAGE_EMAIL}`}
                    >
                      {process.env.REACT_APP_SIGNIN_PAGE_EMAIL}
                    </a>
                  </li>
                )}
                {process.env.REACT_APP_SIGNIN_PAGE_COMPLIANCE_EMAIL && (
                  <li>
                    <FontAwesomeIcon icon={["fas", "envelope"]} /> Compliance
                    Email:{" "}
                    <a
                      href={`mailto:${process.env.REACT_APP_SIGNIN_PAGE_COMPLIANCE_EMAIL}`}
                    >
                      {process.env.REACT_APP_SIGNIN_PAGE_COMPLIANCE_EMAIL}
                    </a>
                  </li>
                )}
                {process.env.REACT_APP_SIGNIN_PAGE_PHONE && (
                  <li>
                    <FontAwesomeIcon icon={["fas", "phone-volume"]} /> Phone:{" "}
                    <a
                      href={`tel:${process.env.REACT_APP_SIGNIN_PAGE_PHONE.replaceAll(
                        /\s/g,
                        ""
                      ).trim()}`}
                    >
                      {process.env.REACT_APP_SIGNIN_PAGE_PHONE}
                    </a>
                  </li>
                )}
              </ul>
            </div>
            <div className="footer-company-info d-flex justify-content-center">
              <FooterSpan />
            </div>
          </div>
        </div>
      </Aux>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    forgetPassword: (objEmail) => {
      dispatch(postForgetPassword(objEmail));
    },
    dismissError: () => {
      dispatch(dismissAuthError());
    },
    showLoading: () => {
      dispatch(setLoading());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
