//need for first render
const initState = {
  list: [],
  loading: false,
};

export default function urls(state = initState, action) {
  switch (action.type) {
      case "FETCH_PAYIN_STATUSES":
          return {
              ...state,
              list: action.payload
          };

      case "LOGOUT":
      case "NOTIFICATION_DISSMISS":
          return initState;

      default:
          return state;
  }
}
