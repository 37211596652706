/**
 * src/api/http.js
 */
 import axios from 'axios';
 import { v4 as uuidv1 } from 'uuid';
 
 import qs from 'qs';
 import _ from 'lodash';
 
 import { getAuthHeader } from '../actions/auth';
 import Config from '../../config';
 
 /**
  * axios instance
  */
 let instance = axios.create({
   paramsSerializer: function (params) {
     return qs.stringify(params, { encode: false })
   }
 })
 
 /**
  *
  * parse error response
  */
 function parseError(messages, errorCode = false) {
   let strError = '';
 
   if (messages) {
     if (!_.isEmpty(messages.info)) {
       messages.info.forEach((error) => {
         strError += error.message + '. ';
       })
       return Promise.reject({ messages: strError })
     }
     else if (messages instanceof Array) {
       messages.forEach((error) => {
         if (error.message) {
           error.title = error.message
         }
 
         strError += error.title + '. ';
       })
       return Promise.reject({ messages: strError })
     } else {
       //general error message
       if (messages != null)
         return Promise.reject({ messages: messages.message })
 
       return Promise.reject({ messages: 'There was a problem in connecting to the server. Please check your internet and try again.' })
     }
   } else {
 
     if (errorCode) {
       //reload 401
       //TODO: use redux to reload
       if (errorCode === 401) {
         let errorMSG = 'Session has expired.  You will be logged-off.'
         sessionStorage.clear()
         sessionStorage.setItem('error', 'Session has expired.  Log-in again.')
         window.location.reload()
         return Promise.reject({ messages: errorMSG, reload: true })
       }
     }
 
     //general error message
     return Promise.reject({ messages: 'There was a problem in connecting to the server. Please check your internet and try again.' })
   }
 
 }
 
 /**
 * parse response
 */
 function parseBody(response) {
 
   if (response.status === 200) {
     return response
   } else if (response.status === 201) {
     return response
   } else if (response.status === 400) {
     return this.parseError(response.data.errors)
   } else if (response.status === 202) {
     return response
   } else {
     return this.parseError(response.data.errors)
   }
 }
 
 
 // request header
 instance.interceptors.request.use(async (config) => {
   // Simply add the token on the headers when getting data
 
   let finalHeaders = {}
 
   let apiToken = await getAuthHeader(true);
   if (apiToken) {
     finalHeaders['Authorization'] = await getAuthHeader(true)
   }
 
   if (config.url.includes(Config.REACT_APP_WALLET_CALCULATION_URI)) {
     finalHeaders['Content-Type'] = 'application/json'
     finalHeaders['x-idempotency-key'] = uuidv1()
   }
 
   //all other security headers for all 
   finalHeaders['X-Frame-Options'] = 'sameorigin'
   finalHeaders['X-XSS-Protection'] = '1; mode=block'
   finalHeaders['Strict-Transport-Security'] = 'max-age=63072000; includeSubDomains; preload'
   finalHeaders['X-Content-Type-Options'] = 'nosniff'
 
   config.headers = finalHeaders
 
 
   return config
 }, error => {
   return Promise.reject(error)
 })
 
 // response parse
 instance.interceptors.response.use((response) => {
   return parseBody(response)
 }, error => {
   // console.warn('Error status', error.response.status)
   // return Promise.reject(error)
 
   //TODO: add refresh token when token is expired
 
   if (error.response) {
     return parseError(error.response.data.errors, error.response.status)
   } else {
     return Promise.reject(error)
   }
 })
 
 const http = instance
 export default http
 