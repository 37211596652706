import Config from "./../../config";

const initState = {
  list: [],
  meta: Config.defaultMeta,
  error: false,
  errorMessage: "",
  loading: false,
  created: {
    confirmed: false,
    product: {},
  },
  selected: {
    open: false,
    product: {
      title: undefined,
      subTitle: undefined,
    },
  },
  selectedProductId: null,
};

export default function products(state = initState, action) {
  switch (action.type) {
    case "LIST_PRODUCTS":
      let { meta } = action;

      return {
        ...state,
        list: action.payload,
        meta: meta ? meta : Config.defaultMeta,
      };

    case "FETCH_PRODUCT_LOAD":
      return {
        ...state,
        selected: {
          ...state.selected,
          loaded: false,
        },
      };

    case "FETCH_PRODUCT_DETAIL":
      return {
        ...state,
        selectedProductId: action.productId,
      };

    case "FETCH_PRODUCT":
      return {
        ...state,
        selected: {
          ...state.selected,
          loaded: true,
          product: {
            ...action.payload,
          },
        },
        loading: false,
      };

    case "PRODUCT_LOADING":
      return {
        ...state,
        loading: true,
      };

    case "PRODUCT_CREATED":
      return {
        ...state,
        loading: false,
        created: {
          confirmed: true,
          product: {
            ...action.payload,
          },
        },
      };

    case "PRODUCT_UPDATED":
      return {
        ...state,
        loading: false,
        selected: {
          ...state.selected,
          confirmed: true,
          product: {
            ...action.payload,
          },
        },
      };

    case "PRODUCT_DISSMISS_CONFIRMED":
      return {
        ...state,
        created: {
          ...state.created,
          confirmed: false,
        },
        selected: {
          ...state.selected,
          confirmed: false,
        },
        loading: false,
      };

    case "PRODUCT_SETTINGS_PANE_SHOW":
      return {
        ...state,
        settings: {
          ...state.settings,
          open: true,
          product: {
            ...action.payload,
          },
        },
      };

    case "PRODUCT_SETTINGS_PANE_HIDE":
      return {
        ...state,
        settings: {
          open: false,
          product: state.settings.product,
        },
      };

    case "HIDE_LOADER":
      return {
        ...state,
        loading: false,
      };

    case "NOTIFICATION_DISSMISS":
      return {
        ...state,
        created: {
          ...state.created,
          confirmed: false,
        },
        selected: {
          ...state.selected,
          confirmed: false,
          loaded: false,
        },
        loading: false,
      };

    case "PRODUCTS_ERROR":
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case "LOGOUT":
      return initState;

    default:
      return state;
  }
}
