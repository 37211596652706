import React from "react";
import { Route, Switch } from "react-router-dom";

import NotFound from "./Containers/UIElements/NotFound";

import AuthenticatedRoute from "./App/components/Auth/AuthenticatedRoute";
import UnauthenticatedRoute from "./App/components/Auth/UnauthenticatedRoute";

import routes from "./routes";

// import LandingPage from "./Containers/UIElements/LandingPage";
import Login from "./Containers/Authentication/SignIn/SignIn1";
import ForgetPassword from "./Containers/Authentication/SignIn/ForgotPassword";
import ChangePassword from "./Containers/Authentication/SignIn/ChangePassword";
import RegisterUser from "./Containers/Authentication/SignIn/RegisterUser";
// import TrustPaymentSuccess from "./Containers/TrustPayment/TrustPaymentSuccess";

// import SuccessPage from "./Containers/Checkout/SuccessPage";
// import ErrorPage from "./Containers/Checkout/ErrorPage";

import Privacy from "./App/components/Privacy";
import Terms from "./App/components/Terms";
import Rates from "./Containers/Authentication/SignIn/Rates";

export default ({ childProps }) =>
  <Switch>
    {routes.map((route, idx) => {
        return (<AuthenticatedRoute path={route.path} exact component={route.component} props={childProps} key={idx} />)
      },
    )}
    {/* {childProps.isAuthenticated && !childProps.isPending && <Route exact path={"/trustpaymentsuccess"} render={props => <TrustPaymentSuccess {...props}/>} />} */}
    {/* {childProps.isAuthenticated ? childProps.isPending && <Route exact path={"/user-pending"} render={props => <UserPending {...props}/>} /> : <Redirect
          to={`/login?redirect=/user-pending`}
        />} */}
    <UnauthenticatedRoute path="/" exact component={Login} props={childProps} />
    <UnauthenticatedRoute path="/link" exact props={childProps} />
    <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
    <UnauthenticatedRoute path="/forgetpassword" exact component={ForgetPassword} props={childProps} />
    <UnauthenticatedRoute path="/auth/resetpassword/:token" exact component={ChangePassword} props={{...childProps, newPassMode: false}} />
    <UnauthenticatedRoute path="/auth/newpassword/:token" exact component={ChangePassword} props={{...childProps, newPassMode: true}} />
    <UnauthenticatedRoute path="/register" exact component={RegisterUser} props={childProps} />

    <UnauthenticatedRoute path="/rates" exact component={Rates} props={childProps} />
    <UnauthenticatedRoute path="/privacy" exact component={Privacy} props={childProps} />
    <UnauthenticatedRoute path="/terms" exact component={Terms} props={childProps} />
    {/* <UnauthenticatedRoute path="/checkout/success" exact component={SuccessPage} props={childProps} />
    <UnauthenticatedRoute path="/checkout/error" exact component={ErrorPage} props={childProps} /> */}

    <Route component={NotFound} />
  </Switch>;
