import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import Aux from "../../hoc/_Aux";
import FooterSpan from "./FooterSpan";

export default class Terms extends Component {
  render() {
    return (
      <Aux>
        <div className="d-flex auth-wrapper aut-bg-img flex-column justify-content-between">
          <div style={{ margin: "30px" }}>
            <div>
              <img
                src={
                  "/assets/tenants/" +
                  process.env.REACT_APP_TENANT.toLowerCase() +
                  "/logo.png"
                }
                alt=""
                className="headerLogo"
              />
            </div>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div className="auth-content-register">
              <div
                className="card"
                style={{
                  minHeight: "50vh",
                  maxHeight: "50vh",
                  overflowY: "scroll",
                }}
              >
                <div className="card-body">
                  <h1>Terms and conditions</h1>
                  <p>
                    These terms and conditions (&quot;Agreement&quot;) set forth
                    the general terms and conditions of your use of the{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={process.env.REACT_APP_URL}
                    >
                      {process.env.REACT_APP_URL}
                    </a>{" "}
                    website (&quot;Website&quot;), &quot;{process.env.REACT_APP_TENANT_TITLE}&quot;
                    mobile application (&quot;Mobile Application&quot;) and any
                    of their related products and services (collectively,
                    &quot;Services&quot;). This Agreement is legally binding
                    between you (&quot;User&quot;, &quot;you&quot; or
                    &quot;your&quot;) and this Website operator and Mobile
                    Application developer (&quot;Operator&quot;, &quot;we&quot;,
                    &quot;us&quot; or &quot;our&quot;). By accessing and using
                    the Services, you acknowledge that you have read,
                    understood, and agree to be bound by the terms of this
                    Agreement. If you are entering into this Agreement on behalf
                    of a business or other legal entity, you represent that you
                    have the authority to bind such entity to this Agreement, in
                    which case the terms &quot;User&quot;, &quot;you&quot; or
                    &quot;your&quot; shall refer to such entity. If you do not
                    have such authority, or if you do not agree with the terms
                    of this Agreement, you must not accept this Agreement and
                    may not access and use the Services. You acknowledge that
                    this Agreement is a contract between you and the Operator,
                    even though it is electronic and is not physically signed by
                    you, and it governs your use of the Services. This terms and
                    conditions policy was created with the help of the{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.websitepolicies.com/terms-and-conditions-generator"
                    >
                      terms and conditions generator
                    </a>
                    .
                  </p>
                  <h2>Accounts and membership</h2>
                  <p>
                    You must be at least 18 years of age to use the Services. By
                    using the Services and by agreeing to this Agreement you
                    warrant and represent that you are at least 18 years of age.
                    If you create an account on the Services, you are
                    responsible for maintaining the security of your account and
                    you are fully responsible for all activities that occur
                    under the account and any other actions taken in connection
                    with it. We may monitor and review new accounts before you
                    may sign in and start using the Services. Providing false
                    contact information of any kind may result in the
                    termination of your account. You must immediately notify us
                    of any unauthorized uses of your account or any other
                    breaches of security. We will not be liable for any acts or
                    omissions by you, including any damages of any kind incurred
                    as a result of such acts or omissions. We may suspend,
                    disable, or delete your account (or any part thereof) if we
                    determine that you have violated any provision of this
                    Agreement or that your conduct or content would tend to
                    damage our reputation and goodwill. If we delete your
                    account for the foregoing reasons, you may not re-register
                    for our Services. We may block your email address and
                    Internet protocol address to prevent further registration.
                  </p>
                  <h2>Links to other resources</h2>
                  <p>
                    Although the Services may link to other resources (such as
                    websites, mobile applications, etc.), we are not, directly
                    or indirectly, implying any approval, association,
                    sponsorship, endorsement, or affiliation with any linked
                    resource, unless specifically stated herein. Some of the
                    links on the Services may be &quot;affiliate links&quot;.
                    This means if you click on the link and purchase an item,
                    the Operator will receive an affiliate commission. We are
                    not responsible for examining or evaluating, and we do not
                    warrant the offerings of, any businesses or individuals or
                    the content of their resources. We do not assume any
                    responsibility or liability for the actions, products,
                    services, and content of any other third parties. You should
                    carefully review the legal statements and other conditions
                    of use of any resource which you access through a link on
                    the Services. Your linking to any other off-site resources
                    is at your own risk.
                  </p>
                  <h2>Prohibited uses</h2>
                  <p>
                    In addition to other terms as set forth in the Agreement,
                    you are prohibited from using the Services or Content: (a)
                    for any unlawful purpose; (b) to solicit others to perform
                    or participate in any unlawful acts; (c) to violate any
                    international, federal, provincial or state regulations,
                    rules, laws, or local ordinances; (d) to infringe upon or
                    violate our intellectual property rights or the intellectual
                    property rights of others; (e) to harass, abuse, insult,
                    harm, defame, slander, disparage, intimidate, or
                    discriminate based on gender, sexual orientation, religion,
                    ethnicity, race, age, national origin, or disability; (f) to
                    submit false or misleading information; (g) to upload or
                    transmit viruses or any other type of malicious code that
                    will or may be used in any way that will affect the
                    functionality or operation of the Services, third party
                    products and services, or the Internet; (h) to spam, phish,
                    pharm, pretext, spider, crawl, or scrape; (i) for any
                    obscene or immoral purpose; or (j) to interfere with or
                    circumvent the security features of the Services, third
                    party products and services, or the Internet. We reserve the
                    right to terminate your use of the Services for violating
                    any of the prohibited uses.
                  </p>
                  <h2>Intellectual property rights</h2>
                  <p>
                    &quot;Intellectual Property Rights&quot; means all present
                    and future rights conferred by statute, common law or equity
                    in or in relation to any copyright and related rights,
                    trademarks, designs, patents, inventions, goodwill and the
                    right to sue for passing off, rights to inventions, rights
                    to use, and all other intellectual property rights, in each
                    case whether registered or unregistered and including all
                    applications and rights to apply for and be granted, rights
                    to claim priority from, such rights and all similar or
                    equivalent rights or forms of protection and any other
                    results of intellectual activity which subsist or will
                    subsist now or in the future in any part of the world. This
                    Agreement does not transfer to you any intellectual property
                    owned by the Operator or third parties, and all rights,
                    titles, and interests in and to such property will remain
                    (as between the parties) solely with the Operator. All
                    trademarks, service marks, graphics and logos used in
                    connection with the Services, are trademarks or registered
                    trademarks of the Operator or its licensors. Other
                    trademarks, service marks, graphics and logos used in
                    connection with the Services may be the trademarks of other
                    third parties. Your use of the Services grants you no right
                    or license to reproduce or otherwise use any of the Operator
                    or third party trademarks.
                  </p>
                  <h2>Limitation of liability</h2>
                  <p>
                    To the fullest extent permitted by applicable law, in no
                    event will the Operator, its affiliates, directors,
                    officers, employees, agents, suppliers or licensors be
                    liable to any person for any indirect, incidental, special,
                    punitive, cover or consequential damages (including, without
                    limitation, damages for lost profits, revenue, sales,
                    goodwill, use of content, impact on business, business
                    interruption, loss of anticipated savings, loss of business
                    opportunity) however caused, under any theory of liability,
                    including, without limitation, contract, tort, warranty,
                    breach of statutory duty, negligence or otherwise, even if
                    the liable party has been advised as to the possibility of
                    such damages or could have foreseen such damages. To the
                    maximum extent permitted by applicable law, the aggregate
                    liability of the Operator and its affiliates, officers,
                    employees, agents, suppliers and licensors relating to the
                    services will be limited to an amount greater of one pound
                    or any amounts actually paid in cash by you to the Operator
                    for the prior one month period prior to the first event or
                    occurrence giving rise to such liability. The limitations
                    and exclusions also apply if this remedy does not fully
                    compensate you for any losses or fails of its essential
                    purpose.
                  </p>
                  <h2>Indemnification</h2>
                  <p>
                    You agree to indemnify and hold the Operator and its
                    affiliates, directors, officers, employees, agents,
                    suppliers and licensors harmless from and against any
                    liabilities, losses, damages or costs, including reasonable
                    attorneys' fees, incurred in connection with or arising from
                    any third party allegations, claims, actions, disputes, or
                    demands asserted against any of them as a result of or
                    relating to your Content, your use of the Services or any
                    willful misconduct on your part.
                  </p>
                  <h2>Severability</h2>
                  <p>
                    All rights and restrictions contained in this Agreement may
                    be exercised and shall be applicable and binding only to the
                    extent that they do not violate any applicable laws and are
                    intended to be limited to the extent necessary so that they
                    will not render this Agreement illegal, invalid or
                    unenforceable. If any provision or portion of any provision
                    of this Agreement shall be held to be illegal, invalid or
                    unenforceable by a court of competent jurisdiction, it is
                    the intention of the parties that the remaining provisions
                    or portions thereof shall constitute their agreement with
                    respect to the subject matter hereof, and all such remaining
                    provisions or portions thereof shall remain in full force
                    and effect.
                  </p>
                  <h2>Dispute resolution</h2>
                  <p>
                    The formation, interpretation, and performance of this
                    Agreement and any disputes arising out of it shall be
                    governed by the substantive and procedural laws of United
                    Kingdom without regard to its rules on conflicts or choice
                    of law and, to the extent applicable, the laws of United
                    Kingdom. The exclusive jurisdiction and venue for actions
                    related to the subject matter hereof shall be the courts
                    located in United Kingdom, and you hereby submit to the
                    personal jurisdiction of such courts. You hereby waive any
                    right to a jury trial in any proceeding arising out of or
                    related to this Agreement. The United Nations Convention on
                    Contracts for the International Sale of Goods does not apply
                    to this Agreement.
                  </p>
                  <h2>Changes and amendments</h2>
                  <p>
                    We reserve the right to modify this Agreement or its terms
                    relating to the Services at any time, effective upon posting
                    of an updated version of this Agreement on the Services.
                    When we do, we will revise the updated date at the bottom of
                    this page. Continued use of the Services after any such
                    changes shall constitute your consent to such changes.
                  </p>
                  <h2>Acceptance of these terms</h2>
                  <p>
                    You acknowledge that you have read this Agreement and agree
                    to all its terms and conditions. By accessing and using the
                    Services you agree to be bound by this Agreement. If you do
                    not agree to abide by the terms of this Agreement, you are
                    not authorized to access or use the Services.
                  </p>
                  <h2>Contacting us</h2>
                  <p>
                    If you would like to contact us to understand more about
                    this Agreement or wish to contact us concerning any matter
                    relating to it, you may send an email to
                    c&#111;&#109;&#112;&#108;ia&#110;&#99;&#101;&#64;&#110;a&#116;i&#111;&#110;t&#114;&#97;ns&#102;er&#46;&#99;o&#109;.
                  </p>
                  <p>This document was last updated on April 13, 2021</p>
                </div>
              </div>
            </div>
          </div>
          <div style={{ margin: "30px 0px" }}>
            <div className="footer-contact-info">
              <ul>
                {process.env.REACT_APP_SIGNIN_PAGE_ADDRESS && (
                  <li>
                    <FontAwesomeIcon icon={["fas", "map-marker-alt"]} />{" "}
                    {process.env.REACT_APP_SIGNIN_PAGE_ADDRESS}
                  </li>
                )}
                {process.env.REACT_APP_SIGNIN_PAGE_EMAIL && (
                  <li>
                    <FontAwesomeIcon icon={["fas", "envelope"]} /> Email:{" "}
                    <a
                      href={`mailto:${process.env.REACT_APP_SIGNIN_PAGE_EMAIL}`}
                    >
                      {process.env.REACT_APP_SIGNIN_PAGE_EMAIL}
                    </a>
                  </li>
                )}
                {process.env.REACT_APP_SIGNIN_PAGE_COMPLIANCE_EMAIL && (
                  <li>
                    <FontAwesomeIcon icon={["fas", "envelope"]} /> Compliance
                    Email:{" "}
                    <a
                      href={`mailto:${process.env.REACT_APP_SIGNIN_PAGE_COMPLIANCE_EMAIL}`}
                    >
                      {process.env.REACT_APP_SIGNIN_PAGE_COMPLIANCE_EMAIL}
                    </a>
                  </li>
                )}
                {process.env.REACT_APP_SIGNIN_PAGE_PHONE && (
                  <li>
                    <FontAwesomeIcon icon={["fas", "phone-volume"]} /> Phone:{" "}
                    <a
                      href={`tel:${process.env.REACT_APP_SIGNIN_PAGE_PHONE.replaceAll(
                        /\s/g,
                        ""
                      ).trim()}`}
                    >
                      {process.env.REACT_APP_SIGNIN_PAGE_PHONE}
                    </a>
                  </li>
                )}
              </ul>
            </div>
            <div className="footer-company-info d-flex justify-content-center">
              <FooterSpan />
            </div>
          </div>
        </div>
      </Aux>
    );
  }
}
