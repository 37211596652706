import React from "react";
import { connect } from "react-redux";
import { Alert } from "reactstrap";
import isStrongPassword from "validator/lib/isStrongPassword";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Aux from "../../../hoc/_Aux";
import LoaderButton from "../../../App/components/LoaderButton";
import FooterSpan from "../../../App/components/FooterSpan";
// import "./../../../assets/scss/style.scss";

import {
  patchResetPassword,
  dismissAuthError,
  setLoading,
} from "../../../store/actions/auth";

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      headingLabel: this.props.newPassMode
        ? "Set Your Password"
        : "Reset Password",
      buttonLabel: this.props.newPassMode ? "Set Password" : "Reset Password",
      confirmText: this.props.newPassMode
        ? "You have successfully set your password."
        : "You have successfully reset your password.",
      autoComplete: this.props.newPassMode
        ? "new-password"
        : "current-password",
      password: "",
      password2: "",
    };
  }

  validateForm = () => {
    var symbolRegex = /^[a-zA-Z\d]*$/;
    let valid = isStrongPassword(this.state.password, {
      minLength: 8,
      minLowercase: 1,
      minUppercase: 1,
      minNumbers: 1,
      minSymbols: 0,
    });
    return (
      valid &&
      !symbolRegex.test(this.state.password) &&
      this.state.password.toString() === this.state.password2.toString()
    );
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleBackToLogin = (event) => {
    this.props.dismissError();
    this.props.history.push("/login");
  };

  handleSubmit = (event, values) => {
    //send request directly
    event.preventDefault();

    this.props.showLoading();

    //format reset password json
    let resetJson = {
      password: this.state.password,
      resetPasswordToken: this.props.match.params.token,
    };

    this.props.resetPassword(resetJson, this.props.history, this.props.newPassMode);
  };

  render() {
    return (
      <Aux>
        <div className="d-flex auth-wrapper aut-bg-img flex-column justify-content-between">
          <div style={{ margin: "30px" }}>
            <div>
              <img
                src={
                  "/assets/tenants/" +
                  process.env.REACT_APP_TENANT.toLowerCase() +
                  "/logo.png"
                }
                alt=""
                className="headerLogo"
              />
            </div>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div className="auth-content">
              <div className="card">
                <div className="card-header d-flex flex-column justify-content-center align-items-center">
                  <h3 className="mb-3">{this.state.headingLabel}</h3>
                  <span className="text-italic">
                    <ul>
                      <li>Minimum 8 characters</li>
                      <li>Minimum 1 Uppercase character</li>
                      <li>Minimum 1 Lowercase character</li>
                      <li>Minimum 1 Numerical character</li>
                      <li>Minimum 1 Special character</li>
                    </ul>
                  </span>
                </div>
                <div className="card-body">
                  <Alert
                    color="danger"
                    isOpen={this.props.auth.error}
                    toggle={this.props.dismissError}
                  >
                    {this.props.auth.errorMsg}
                  </Alert>
                  <div>
                    <form onSubmit={this.handleSubmit}>
                      <div className="input-group mb-3">
                        <input
                          type="password"
                          id="password"
                          className="form-control"
                          placeholder="New Password"
                          value={this.state.password}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                      <div className="input-group mb-4">
                        <input
                          type="password"
                          id="password2"
                          className="form-control"
                          placeholder="Re-type New Password"
                          value={this.state.password2}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                      <LoaderButton
                        block
                        disabled={
                          this.props.auth.loading || !this.validateForm()
                        }
                        type="submit"
                        isLoading={this.props.auth.loading}
                        text={this.state.buttonLabel}
                        loadingText="Loading..."
                        className="px-4 btn-login"
                      />
                    </form>
                  </div>
                </div>
                <div className="card-footer d-flex flex-row justify-content-center align-items-center">
                  <div className="d-flex flex-column">
                    <h5>{process.env.REACT_APP_ASK_REGISTER_CATCHPHRASE}</h5>
                    <span className="">
                      {process.env.REACT_APP_ASK_REGISTER_SUB_CATCHPHRASE}
                    </span>
                  </div>
                  <div style={{ minWidth: "100px" }}>
                    <LoaderButton
                      block
                      disabled={this.props.auth.loading}
                      onClick={this.handleBackToLogin}
                      text="Login"
                      className="px-4 btn-secondary ml-3"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="login-footer">
              <span className="text-white">
                {process.env.REACT_APP_MAIN_CATCHPHRASE}
              </span>
            </div>
          </div>
          <div style={{ margin: "30px 0px" }}>
            <div className="footer-contact-info">
              <ul>
                {process.env.REACT_APP_SIGNIN_PAGE_ADDRESS && (
                  <li>
                    <FontAwesomeIcon icon={["fas", "map-marker-alt"]} />{" "}
                    {process.env.REACT_APP_SIGNIN_PAGE_ADDRESS}
                  </li>
                )}
                {process.env.REACT_APP_SIGNIN_PAGE_EMAIL && (
                  <li>
                    <FontAwesomeIcon icon={["fas", "envelope"]} /> Email:{" "}
                    <a
                      href={`mailto:${process.env.REACT_APP_SIGNIN_PAGE_EMAIL}`}
                    >
                      {process.env.REACT_APP_SIGNIN_PAGE_EMAIL}
                    </a>
                  </li>
                )}
                {process.env.REACT_APP_SIGNIN_PAGE_COMPLIANCE_EMAIL && (
                  <li>
                    <FontAwesomeIcon icon={["fas", "envelope"]} /> Compliance
                    Email:{" "}
                    <a
                      href={`mailto:${process.env.REACT_APP_SIGNIN_PAGE_COMPLIANCE_EMAIL}`}
                    >
                      {process.env.REACT_APP_SIGNIN_PAGE_COMPLIANCE_EMAIL}
                    </a>
                  </li>
                )}
                {process.env.REACT_APP_SIGNIN_PAGE_PHONE && (
                  <li>
                    <FontAwesomeIcon icon={["fas", "phone-volume"]} /> Phone:{" "}
                    <a
                      href={`tel:${process.env.REACT_APP_SIGNIN_PAGE_PHONE.replaceAll(
                        /\s/g,
                        ""
                      ).trim()}`}
                    >
                      {process.env.REACT_APP_SIGNIN_PAGE_PHONE}
                    </a>
                  </li>
                )}
              </ul>
            </div>
            <div className="footer-company-info d-flex justify-content-center">
              <FooterSpan />
            </div>
          </div>
        </div>
      </Aux>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (objEmail, history, newPassMode) => {
      dispatch(patchResetPassword(objEmail, history, newPassMode));
    },
    dismissError: () => {
      dispatch(dismissAuthError());
    },
    showLoading: () => {
      dispatch(setLoading());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
