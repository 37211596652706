//simply add process env to config file
const config = {
  ...process.env
}

export default {
    ...config,
    defaultMeta: { //used for table defaults
      itemTotal: process.env.REACT_APP_DEFAULT_PAGINATE_ITEM_TOTAL,
      pageNumber: process.env.REACT_APP_DEFAULT_PAGINATE_PAGE_NUMBER,
      pageSize: process.env.REACT_APP_DEFAULT_PAGINATE_PAGE_SIZE,
      pageTotal: process.env.REACT_APP_DEFAULT_PAGINATE_PAGE_TOTAL,
      sort: process.env.REACT_APP_DEFAULT_PAGINATE_SORT_FIED
    },
    defaultPath: '/home',
    basename: '',
    layout: 'vertical',
    preLayout: null,
    collapseMenu: false,
    layoutType: 'menu-light',
    navIconColor: false,
    headerBackColor: 'header-default',
    navBackColor: 'navbar-default',
    navBrandColor: 'brand-default',
    navBackImage: false,
    rtlLayout: false,
    navFixedLayout: true,
    headerFixedLayout: false,
    boxLayout: false,
    navDropdownIcon: 'style1',
    navListIcon: 'style1',
    navActiveListColor: 'active-default',
    navListTitleColor: 'title-default',
    navListTitleHide: false,
    configBlock: false,
    layout6Background : 'linear-gradient(to right, #A445B2 0%, #D41872 52%, #FF0066 100%)',
    layout6BackSize : '',
};
